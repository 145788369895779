/* eslint-disable */
import createPersistedState from 'vuex-persistedstate'
import Vue from 'vue';
import Vuex from 'vuex';
import { buildtree, generator } from '@/unit/router';

Vue.use(Vuex);


let newA = []
let buttonAuth = []
try {
  newA = [
    {
      "id": "f1220d42-0108-4092-863c-0f4351d71a49",
      "parentId": "0",
      "name": "首页",
      "code": "",
      "url": "/",
      "type": 1,
      "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
      "sort": 10,
      "isScreen": "Y",
      "children": [],
    },
    {
      "id": "a2172897-1a96-4598-b665-0a49c5a0c982",
      "parentId": "0",
      "name": "智能监控",
      "code": "",
      "url": "/intelligentMonitoring",
      "type": 1,
      "sort": 1,
      "isScreen": "Y",
      "button": [],
      "children": [{
        "id": "0583a28d-26a6-4c8b-90f0-f294090fe498",
        "parentId": "a2172897-1a96-4598-b665-0a49c5a0c982",
        "name": "设备管理",
        "code": "",
        "url": "/intelligentMonitoring/IntelligentManger",
        "type": 1,
        "sort": 1,
        "isScreen": "Y",
        "button": [],
        "children": [],
      }, {
        "id": "7d98eacb-0438-4493-83bf-2d47a74f7016",
        "parentId": "a2172897-1a96-4598-b665-0a49c5a0c982",
        "name": "设备监控",
        "code": "",
        "url": "/intelligentMonitoring/equipmentMonitoring",
        "type": 1,
        "sort": 2,
        "isScreen": "Y",
        "button": [],
        "children": [],
      }, {
        "id": "27ea9bcf-41fa-4d31-bd4a-9362ac50ba23",
        "parentId": "a2172897-1a96-4598-b665-0a49c5a0c982",
        "name": "监控图表",
        "code": "",
        "url": "/intelligentMonitoring/ReceiverAndUnit",
        "type": 1,
        "sort": 3,
        "isScreen": "Y",
        "button": [],
        "children": [],
      }, {
        "id": "7654bb21-ac49-47f0-9679-922b86358fcf",
        "parentId": "a2172897-1a96-4598-b665-0a49c5a0c982",
        "name": "告警记录",
        "code": "",
        "url": "/intelligentMonitoring/supplier",
        "type": 1,
        "sort": 4,
        "isScreen": "Y",
        "button": [],
        "children": [],
      }, {
        "id": "45a572a3-79fe-4d9a-ad57-d67f41048376",
        "parentId": "a2172897-1a96-4598-b665-0a49c5a0c982",
        "name": "告警规则配置",
        "code": "",
        "url": "/intelligentMonitoring/waring",
        "type": 1,
        "sort": 5,
        "isScreen": "Y",
        "button": [],
        "children": [],
      }],
    }, {
      "id": "cac98387-34ab-4254-8538-c4a611b23056",
      "parentId": "0",
      "name": "大屏服务",
      "code": "",
      "url": "/bigScreen",
      "type": 1,
      "sort": 1,
      "isScreen": "Y",
      "button": [],
      "children": [],
    }, {
      "id": "f1220d42-0108-4092-863c-0f4351d71a49",
      "parentId": "0",
      "name": "系统管理",
      "code": "",
      "url": "/system",
      "type": 1,
      "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
      "sort": 10,
      "isScreen": "Y",
      "button": [],
      "children": [{
        "id": "fad26c2e-a221-42e5-8c15-6cbfc517c099",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "用户管理",
        "code": "",
        "url": "/system/user",
        "type": 1,
        "sort": 10,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_RESET", "BTN_REMOVE"],
        "children": [],
      }, {
        "id": "0f9bc0dc-6a2b-4ddc-be35-0f7918876170",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "角色管理",
        "code": "",
        "url": "/system/role",
        "type": 1,
        "sort": 20,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      }, {
        "id": "cbd85f6a-3e1e-47ae-9d4a-1048ed89eee1",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "菜单管理",
        "code": "",
        "url": "/system/resource",
        "type": 1,
        "sort": 30,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      },
      {
        "id": "cbd85f6a-3e1e-47ae-9d4a-1048ed89eee1",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "部门管理",
        "code": "",
        "url": "/system/department",
        "type": 1,
        "sort": 30,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      },
      {
        "id": "cbd85f6a-3e1e-47ae-9d4a-1048ed89eee2",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "字典管理",
        "code": "",
        "url": "/system/dictionaries",
        "type": 1,
        "sort": 30,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      },
      {
        "id": "cbd85f6a-3e1e-47ae-9d4a-1048ed89eee3",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "参数管理",
        "code": "",
        "url": "/system/parameter",
        "type": 1,
        "sort": 30,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      },
      {
        "id": "cbd85f6a-3e1e-47ae-9d4a-1048ed89eee4",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "文件管理",
        "code": "",
        "url": "/system/file",
        "type": 1,
        "sort": 30,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      },
      {
        "id": "cbd85f6a-3e1e-47ae-9d4a-1048ed89eee5",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "地区管理",
        "code": "",
        "url": "/system/area",
        "type": 1,
        "sort": 30,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      }],
    },
    {
      "id": "f1220d42-0108-4092-863c-0f4351d71a49",
      "parentId": "0",
      "name": "系统监控",
      "code": "",
      "url": "/log",
      "type": 1,
      "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
      "sort": 10,
      "isScreen": "Y",
      "button": [],
      "children": [{
        "id": "fad26c2e-a221-42e5-8c15-6cbfc517c099",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "操作日志",
        "code": "",
        "url": "/log/operation",
        "type": 1,
        "sort": 10,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_RESET", "BTN_REMOVE"],
        "children": [],
      }, {
        "id": "0f9bc0dc-6a2b-4ddc-be35-0f7918876170",
        "parentId": "f1220d42-0108-4092-863c-0f4351d71a49",
        "name": "登录日志",
        "code": "",
        "url": "/log/login",
        "type": 1,
        "sort": 20,
        "isScreen": "Y",
        "button": ["BTN_LIST", "BTN_CREATE", "BTN_EDIT", "BTN_REMOVE"],
        "children": [],
      }],
    },
    // 新版
    {
      "id": "f1220d42-0108-4092-863c-0f4351d71a49",
      "parentId": "0",
      "name": "场馆运营",
      "code": "",
      "url": "/venueOperation",
      "type": 1,
      "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
      "sort": 10,
      "isScreen": "Y",
      "children": [
        {
          "id": "f1220d42-0108-4092-863c-0f4351d71a49",
          "parentId": "0",
          "name": "我的场馆",
          "code": "",
          "url": "/",
          "type": 1,
          "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
          "sort": 10,
          "isScreen": "Y",
          "children": [],
        },
        {
          "id": "f1220d42-0108-4092-863c-0f4351d71a49",
          "parentId": "0",
          "name": "开单",
          "code": "",
          "url": "/",
          "type": 1,
          "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
          "sort": 10,
          "isScreen": "Y",
          "children": [],
        },
        {
          "id": "f1220d42-0108-4092-863c-0f4351d71a49",
          "parentId": "0",
          "name": "验票记录",
          "code": "",
          "url": "/venueOperation/ticketRecord",
          "type": 1,
          "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
          "sort": 10,
          "isScreen": "Y",
          "children": [],
        },
        {
          "id": "f1220d42-0108-4092-863c-0f4351d71a49",
          "parentId": "0",
          "name": "订单记录",
          "code": "",
          "url": "/venueOperation/orderRecord",
          "type": 1,
          "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
          "sort": 10,
          "isScreen": "Y",
          "children": [],
        },
        {
          "id": "f1220d42-0108-4092-863c-0f4351d71a49",
          "parentId": "0",
          "name": "开单",
          "code": "",
          "url": "/venueOperation/order",
          "type": 1,
          "iconPath": "D:\\site\\亚沙\\OneSports.WuZi.WebApi\\wwwroot/Files/Resource/Icon/xx.png",
          "sort": 10,
          "isScreen": "Y",
          "children": [],
        },
      ],
    },
  ]
  buttonAuth = JSON.parse(sessionStorage.getItem('buttonAuth')) || []
} catch (e) {
  //
}
export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    LOGODIAPLAY: '1', // logo 显示隐藏
    LOGOSWITCH: 1, // 判断页面是否存在logo和相关文字，1显示，0隐藏
    NAME: '', // 当前用户名
    USERNAME: '', // 当前账号
    USERTYPE: '', // 当前用户类型
    USERID: '', // 当前用户ID
    SYSTEMID: '', // 当前系统ID
    MYBROWSER: '', // 浏览器
    TOKEN: '', // TOKEN
    timestampDiff: 0, // 服务端与客户端的时间差
    DROPDOWNBOX: [],
    authority: [],
    buttonAuth: [],
    Config: {},
    Language: 0,
    venueId: '', // 场馆id
    changePay: 0,
    venueList: [], //场馆列表
    typeList: [],
    cardList: [], // 卡列表
    pureCardList: [], // 纯卡列表
    ROLES: [], // 角色列表,
    SystemName: '', //系统名称
    SystemIcon: '', //系统图标
    merchantAccountDetailInfoDTO: {}, // 商户信息
    serviceMemberInfo: {}
  },
  mutations: {
    setDropDownbox(state, payload) {
      state.DROPDOWNBOX = payload
      if (payload.VENUE_ORDER_TYPE.length > 0) {
        payload.VENUE_ORDER_TYPE.forEach((item, index) => {
          if (item.value === '5') {
            payload.VENUE_ORDER_TYPE.splice(index, 1)
          }
        })
      }
      state.typeList = [{ label: '全部消息', value: '-1' }, ...payload.VENUE_ORDER_TYPE]
    },
    setVenueId(state, payload) {
      console.log("setVenueId -> payload", payload)
      state.venueId = payload
    },
    setVenueList(state, payload) {
      state.venueList = []
      state.venueList = [...payload]
      // console.log("setVenueList -> venueList", state.venueList)
    },
    setCardList(state, payload) {
      state.cardList = []
      state.cardList = [...payload]
    },
    setPureCardList(state, payload) {
      state.pureCardList = []
      state.pureCardList = [...payload]
    },
    setUserName(state, payload) {
      state.USERNAME = payload
    },
    setRoles(state, payload) {
      state.ROLES = payload
    },
    setMerchantAccountDetail(state, payload) {
      state.merchantAccountDetailInfoDTO = payload
    },
    setName(state, payload) {
      state.NAME = payload
    },
    setUserType(state, payload) {
      state.USERTYPE = payload
    },
    setUserId(state, payload) {
      state.USERID = payload
    },
    setSystemId(state, payload) {
      state.SYSTEMID = payload
    },
    setMyBrowser(state, payload) {
      state.MYBROWSER = payload
    },
    setAuthority(state, payload) {
      state.authority = payload
    },
    setToken(state, payload) {
      state.TOKEN = payload
      // dropBox()
    },
    setTimestampDiff(state, payload) {
      state.timestampDiff = payload
    },
    setLogoSwitch(state, payload) {
      state.LOGOSWITCH = payload
    },
    setButtonAuth(state, payload) {
      state.buttonAuth = payload
    },
    setEventId(state, payload) {
      state.eventId = payload
    },
    setLanguage(state) {
      state.Language = state.Language === 1 ? 0 : 1
      window.v.$i18n.locale = state.Language === 1 ? 'zh' : 'en'
    },
    setConfig(state, payload) {
      state.Config = {
        ...state.Config,
        ...payload,
      }
    },
    changePay(state, payload) {
      state.changePay = payload
    },
    setServiceMemberInfo(state, payload) {
      state.serviceMemberInfo = payload
    },
    setSystemName(state, payload) {
      state.SystemName = payload
    },
    setSystemIcon(state, payload) {
      state.SystemIcon = payload
    }
  },
  actions: {
    /**
     * @function 登录方法
     * @param {String} username 用户名
     * @param {Number} id 用户ID
     * @param {String} token TOKEN
     * @param {String} name 用户姓名
     * @param {Number} systemId 系统ID
     * @param {Array} authority 用户权限列表
     * @description 不用分开赋值，只要把后端返回的res.data丢到变量里即可
     */
    Login(context, {
      token,
    }) {
      context.commit('setToken', token)
      /* context.commit('setUserName', loginName)
      context.commit('setUserId', id)
      context.commit('setSystemId', systemId) */
    },
    setRouter(context, data) {
      let isPrice = data.filter(item => item.menuKey === 'changePrice').length
      context.commit('changePay', isPrice)
      const arr = []
      buildtree(data, arr, 0)
      let menuList = generator(arr);
      console.log("setRouter -> menuList==>目录目录结果", menuList)
      menuList.forEach((item) => {
        if (item.meta.title === "首页") {
          item.meta.icon = require("../assets/images/menu_venu.png");
        } else if (item.meta.title === "系统管理") {
          item.meta.icon = require("../assets/images/menu_set.png");
        } else if (item.meta.title === "场馆管理") {
          item.meta.icon = require("../assets/images/menu_venueM.png");
        } else if (item.meta.title === "场馆运营") {
          item.meta.icon = require("../assets/images/menu_opera.png");
        } else if (item.meta.title === "智能监控") {
          item.meta.icon = require("../assets/images/menu_jiank_o.png");
        } else if (item.meta.title === "系统监控") {
          item.meta.icon = require("../assets/images/menu_system.png");
        } else if (item.meta.title === "流程管理") {
          item.meta.icon = require("../assets/images/menu_process.png");
        } else if (item.meta.title === "场馆大屏") {
          item.meta.icon = require("../assets/images/menu_screen.png");
        } else if (item.meta.title === "体育赛事") {
          item.meta.icon = require("../assets/images/menu_competition.png");
        } else if (item.meta.title === "资讯管理") {
          item.meta.icon = require("../assets/images/menu_info.png");
        } else if (item.meta.title === "成绩查询") {
          item.meta.icon = require("../assets/images/menu_grade.png");
        } else if (item.meta.title === "便民服务") {
          item.meta.icon = require("../assets/images/convenience.png");
        } else if (item.meta.title === "必游景点管理") {
          item.meta.icon = require("../assets/images/mustSeeSpot.png");
        } else if (item.meta.title === "VR管理") {
          item.meta.icon = require("../assets/images/VRManage.png");
        } else if (item.meta.title === "基础设置") {
          item.meta.icon = require("../assets/images/base.png");
        } else if (item.meta.title === "赛事邀请") {
          item.meta.icon = require("../assets/images/menu_saishiyaoqing.png");
        } else if (item.meta.title === "报表管理") {
          item.meta.icon = require("../assets/images/menu_grade.png");
        } else if (item.meta.title === "健身指导") {
          item.meta.icon = require("../assets/images/bodyBuilding.png");
        } else if (item.meta.title === "健身设施") {
          item.meta.icon = require("../assets/images/fitness.png");
        } else if (item.meta.title === "体质监测管理") {
          item.meta.icon = require("../assets/images/tizhi.png");
        } else if (item.meta.title === "体质检测点") {
          item.meta.icon = require("../assets/images/tizhi.png");
        } else if (item.meta.title === "智能设备") {
          item.meta.icon = require("../assets/images/intelligent_device/device-home.png");
        } else if (item.meta.title === "设备列表") {
          item.meta.icon = require("../assets/images/intelligent_device/deviceList.png");
        } else if (item.meta.title === "物联网设备") {
          item.meta.icon = require("../assets/images/intelligent_device/deviceList.png");
        } else if (item.meta.title === "设备告警记录") {
          item.meta.icon = require("../assets/images/intelligent_device/device_alarm.png");
        } else if (item.meta.title === "人体高温告警记录") {
          item.meta.icon = require("../assets/images/intelligent_device/high-temper_alarm.png");
        } else if (item.meta.title === "视频直播") {
          item.meta.icon = require("../assets/images/menu_live.png");
          item.meta.icon = require("../assets/images/intelligent_device/high-temper_alarm.png");
        } else if (item.meta.title === "统计报表") {
          item.meta.icon = require("../assets/images/report.png");
        } else if (item.meta.title === "活动管理") {
          item.meta.icon = require("../assets/images/menu_actived.png");
        } else if (item.meta.title === "优惠券管理") {
          item.meta.icon = require("../assets/images/menu_coupon.png");
        } else if (item.meta.title === "主体管理") {
          item.meta.icon = require("../assets/images/mainMg.png");
        } else if (item.meta.title === "商户管理") {
          item.meta.icon = require("../assets/images/merchantMg.png");
        } else if (item.meta.title === "商户订单") {
          item.meta.icon = require("../assets/images/details.png");
        } else if (item.meta.title === "商户结算") {
          item.meta.icon = require("../assets/images/check.png");
        } else if (item.meta.title === "结算管理") {
          item.meta.icon = require("../assets/images/balance.png");
        } else if (item.meta.title === "产品中心") {
          item.meta.icon = require("../assets/images/product-icon.png");
        } else if (item.meta.title === "闸机管理") {
          item.meta.icon = require("../assets/images/menu_screen.png");
        } else {
          item.meta.icon = item.meta.icon;
        }
      });
      console.log("setRouter===》目录", menuList)
      context.commit('setAuthority', menuList)
    },
    /**
     * 
     * @function 系统名称 
     */
    getSystemName(context, name) {
      context.commit('setSystemName', name);
    },
    getSystemIcon(context, icon) {
      context.commit('setSystemIcon', icon)
    },
    /**
     * @function 退出登录
     */
    LoginOut(context) {
      context.commit('setUserName', '')
      context.commit('setUserId', '')
      context.commit('setToken', '')
      context.commit('setSystemId', '')
      // 使用Store 方法操作共享状态
      context.commit('setName', '')
      context.commit('changePay', 0)
      context.commit('setRoles', [])
      context.commit('setVenueId', '')
      context.commit('setVenueList', [])
      context.commit('setMerchantAccountDetail', {})
      context.commit('setServiceMemberInfo', {})
    },
    /**
     * @function 设置用户信息
     * @param {String} loginName 用户名
     * @param {Number} userId 用户ID
     * @param {String} userName 用户姓名
     * @description 不用分开赋值，只要把后端返回的res.data丢到变量里即可
     */
    setUserInfo(context, {
      loginName, userId, userName, buttons, roles, merchantAccountDetailInfoDTO
    }) {
      context.commit('setUserName', loginName)
      context.commit('setUserId', userId)
      context.commit('setName', userName)
      context.commit('setButtonAuth', buttons)
      context.commit('setRoles', roles)
    },
    setMerchantInfo(context, {
      data
    }) {
      context.commit('setMerchantAccountDetail', data)
    },
    /**
     * @function 切换语言
     * @param {Number} language 语言0为中文，1为英文，默认中文
     */
    setLanguage(context) {
      context.commit('setLanguage')
    },
    /**
     * @function 设置配置
     * @param {Number} timestampDiff
     */
    setConfig(context, {
      timestamp,
      verificationSwitch,
    }) {
      context.commit('setConfig', {
        verificationSwitch,
      })
      const timestampDiff = new Date().getTime() - timestamp
      context.commit('setTimestampDiff', timestampDiff)
    },
    /**
     * @function 设置下拉到VUEX
     * @param {Array} data 获取到的下拉列表
     */
    setDict(context, {
      data,
    }) {
      context.commit('setDropDownbox', data)
    },
    /**
     * @function 设置按钮权限到VUEX
     * @param {Array} data 当前页面的按钮权限数组
     */
    setButtonAuth(context, data) {
      context.commit('setButtonAuth', data)
    },
  },
  modules: {

  },
});
