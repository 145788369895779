/* eslint-disable */
class searchFrom {
    constructor(type) {
      if (type === 'form') {
        this.withdrawalAmount = ''
        this.merchantAccount = '' // 商户账号
        this.merchantAccountId = '' // 商户ID
        this.merchantName = '' // 商户名称
      } else if (type === 'rule') {
        // this.withdrawalAmount = [{ required: true, message: '请输入提现金额', trigger: ['change', 'blur'] }]
      }
    }
  }
  export default searchFrom
  