<template>
  <div class="tab-wrap">
    <div class="tab-container">
      <el-tabs v-model="TabsActive" type="card" :closable="tabsList.length !== 1" @tab-remove="removeTab" :before-leave="changeTabEvent">
        <el-tab-pane
          v-for="(item, index) in tabsList"
          :key="`${item.name}${index}`"
          :label="item.title"
          :name="item.name">
      </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <el-popover
      placement="bottom"
      width="100"
      v-model="showLoginOut"
      class="user-option"
      trigger="hover">
      <p class="option-text" @click="loginOut">{{$t('loginOut')}}</p>
      <p class="user-option-welcome" slot="reference">{{$t('welcome')}}，{{USERNAME}}<i class="el-icon-caret-bottom"></i></p>
    </el-popover> -->
    <!-- <el-select class="venueId" v-model="venueIdCopy" @change="reloadPage" filterable>
      <el-option :value="item.id" :label="item.venueName" v-for="item in venueList" :key="item.id"></el-option>
    </el-select> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import apis from '@/apis'

export default {
  data() {
    return {
      // 默认第一个要首页
      tabsList: [
        {
          title: "首页",
          name: "/",
          content: "/",
        },
      ],
      venueList: [],
      TabsActive: '',
      venueIdCopy: '',
      showLoginOut: false,
    }
  },
  computed: {
    ...mapState(['USERNAME', 'venueId']),
  },
  watch: {
    $route(to) {
      this.addTab(to)
    },
  },
  methods: {
    ...mapActions({
      setLanguage: 'setLanguage',
      LoginOut: 'LoginOut',
    }),
    ...mapMutations(['setVenueId']),
    /*
    * 清除tab
    */
    clearTab() {
      this.tabsList = [this.tabsList[0]]
    },
    /**
     * @function 添加标签
     * @param {String} fullPath 打开的网页的完整路径
     * @param {Object} meta 路由里面的meta标签
     */
    addTab({
      fullPath,
      meta,
    }) {
      const whiteList = ['登录']
      if (!this.tabsList.map(item => item.content).includes(fullPath) && !whiteList.includes(meta.title)) {
        this.tabsList.push({
          title: meta.title,
          name: fullPath,
          content: fullPath,
        })
      }
      console.log("tabsList", this.tabsList)
      this.TabsActive = fullPath
    },
    /**
     * @function 删除标签页
     * @param {String} targetName 要被删除的表情的name属性
     */
    removeTab(targetName) {
      const tabs = this.tabsList
      let activeName = this.TabsActive
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.TabsActive = activeName;
      this.tabsList = tabs.filter(tab => tab.name !== targetName);
    },
    /**
     * @function 当标签页修改的时候，进行跳转
     * @param {String} activeName 要跳转的地址URL
     */
    changeTabEvent(activeName) {
      if (this.$route.fullPath !== activeName && activeName !== '0') {
        this.$router.push(activeName)
      }
    },
    /**
     * @function 当选项卡进行切换时，改变事件
     * @param {String} activeName 要跳转的地址URL
     */
    changeVenue() {
      this.$emit('changeVenue');
    },
    /**
     * @function 退出登录
     */
    loginOut() {
      this.showLoginOut = false
      this.$http.post(apis.loginOut).then((res) => {
        if (res.data.code === 0) {
          // 退出成功
          this.LoginOut()
          this.$router.push('/login')
        }
      }).catch(() => {
        this.LoginOut()
        this.$router.push('/login')
      })
    },
    /**
     * @function 往组件外传递打开修改密码弹窗的事件
     */
    changePassword() {
      this.$emit('changePassword', true)
    },
    reloadPage() {
      this.setVenueId(this.venueIdCopy)
      this.$nextTick(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
      })
      // setTimeout(() => {
      // }, 500);
    },
  },
  mounted() {
    this.addTab(this.$route)
    this.$http.post(apis.venuevManagement_list, { id: '' }).then((res) => {
      if (res.data.code === 0) {
        this.venueList = res.data.rows
        if (this.venueList.length > 0) {
          this.venueIdCopy = this.venueId ? this.venueId : this.venueList[0].id
          this.$nextTick(() => {
            this.setVenueId(this.venueIdCopy)
          })
        }
      }
    }).then((res) => {
      console.log(res)
    })
  },
  activated() {
    this.$http.post(apis.venuevManagement_list, { id: '' }).then((res) => {
      if (res.data.code === 0) {
        this.venueList = res.data.rows
        if (this.venueList.length > 0) {
          console.log(this.venueId)
          console.log(this.venueList[0].id)
          this.venueIdCopy = this.venueId ? this.venueId : this.venueList[0].id
          this.$nextTick(() => {
            this.setVenueId(this.venueIdCopy)
          })
        }
      }
    }).then((res) => {
      console.log(res)
    })
  },
}
</script>
<style lang="less" scoped>
@import url('../../src/assets/styles/mixin.scoped.less');
.tab-wrap{
  height:50px;
  background:rgba(243,245,247,1);
  box-shadow:0px 2px 6px 0px rgba(207,207,207,0.5);
  // display: grid;
  // grid-template-columns: auto 200px 200px;
  .user-option {
    line-height: 60px;
    height: 60px;
    display: block;
    cursor: pointer;
  }
  .el-icon-caret-bottom{
    color: @logined-user-down-icon;
  }
  .language-button{
    font-size:18px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(23,49,222,1);
    line-height:60px;
    text-align: center;
    cursor: pointer;
  }
}
.option-text{
  line-height: 30px;
  height: 30px;
  text-align: center;
  display: block;
  cursor: pointer;
}
.venueId {
  margin-top: 12px;
}
</style>
<style lang="less">
.tab-container >.el-tabs--card > .el-tabs__header .el-tabs__item .el-icon-close {
  right: -18px
}
.el-tabs__item .el-icon-close:hover {
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable .el-icon-close {
  width: 18px !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable .el-icon-close {
   width: 18px;
  height: 18px;
  line-height: 18px;
}
.tab-wrap {
  .el-tabs__item{
    height: 50px;
    line-height: 50px;
    padding: 0 59px !important;
    .el-icon-close {
      background: #D5DAE3;
      color: #fff;
      width: 18px;
      height: 18px;
      line-height: 18px;
      font-size: 12px;
    }
  }
  .el-tabs--card > .el-tabs__header{
    border-bottom: none;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
    border-bottom: none;
  }
  .el-tabs__item.is-active{
    color: #1731DE;
  }
  .el-tabs__item:hover{
    color: #1731DE;
  }
}
.venueId {
}
.venueId .el-input--suffix .el-input__inner{
  border: none !important;
  background: none !important;
  text-align: center;
}
.el-tabs__nav .el-tabs__item:nth-child(1) span{
   display: none !important;
}
</style>
