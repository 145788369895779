import Vue from 'vue';
import "babel-polyfill"
import BaiduMap from 'vue-baidu-map';
import VCharts from 'v-charts';
import http from './plugins/axios'
import App from './App.vue';
import store from './store';
import router from './router';
import './plugins/element';
import i18n from './i18n';
import '@/store/watchPathExist.js'
import auth from '@/directives/auth.js'

Vue.directive('auth', auth);

Vue.use(VCharts)
Vue.config.productionTip = false;

Vue.prototype.$env = process.env

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'uOONS3ZiGapapMDDpXdFGcYNfxHglkYx',
})
Vue.prototype.$http = http
window.v = new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
