<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      title="提现"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="20" class="content-label">
            <el-form-item label="到账银行卡信息:"></el-form-item>
          </el-col>
          <el-col :span="20" class="content-label">
            <el-form-item label="开户行名称:"
              ><span>{{
                merchantAccountDetailInfoDTO.bankName
              }}</span></el-form-item
            >
          </el-col>
          <el-col :span="20" class="content-label">
            <el-form-item label="账户名:"
              ><span>{{
                merchantAccountDetailInfoDTO.accountName
              }}</span></el-form-item
            >
          </el-col>
          <el-col :span="20" class="content-label">
            <el-form-item label="银行账号:"
              ><span>{{
                merchantAccountDetailInfoDTO.accountCode
              }}</span></el-form-item
            >
          </el-col>
          <el-col :span="20" class="content-label">
            <el-form-item label="可用余额:"
              ><span class="content-label-text">{{
                merchantAccountDetailInfoDTO.availableAmount || 0
              }}</span
              ><span class="content-label-text1">元</span></el-form-item
            >
          </el-col>
          <el-col :span="20">
            <el-form-item label="提现金额:" prop="withdrawalAmount">
              <el-input v-model="form.withdrawalAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" class="content-label">
            <el-form-item label=" ">
              <span>100元起提，</span>
              <span class="content-label-text2" @click="toWithdrawal"
                >全部提现</span
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">提现</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import WithdrawDialogClass from "./withdrawDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  components: {},
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    const validateSort = (rule, value, callback) => {
      // const sort = value.replace(/\s/g, "");//去除空格
      //校验排序
      const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
      if (!value) {
        callback([new Error("请输入打账金额")]);
      } else if (!reg.test(value)) {
        callback([new Error("打账金额输入不合法")]);
      } else if (
        Number(value) > this.merchantAccountDetailInfoDTO.availableAmount
      ) {
        callback([new Error("提现金额不得大于可用余额")]);
      } else {
        callback();
      }
    };
    return {
      form: new WithdrawDialogClass("form"), // 实例化一个表单的变量
      rules: {
        withdrawalAmount: [
          {
            required: true,
            validator: validateSort,
            trigger: ["change", "blur"],
          },
        ],
      },
      apis,
      isShow: true,
      id: "",
    };
  },
  computed: {
    ...mapState([
      "DROPDOWNBOX",
      "TOKEN",
      "venueList",
      "merchantAccountDetailInfoDTO",
    ]),
  },
  activated() {},
  methods: {
    toWithdrawal() {
      this.form.withdrawalAmount = this.merchantAccountDetailInfoDTO.availableAmount || '0';
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();
      this.$emit("closeDialog", false);
    },
    ...mapMutations(["setVenueId", "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new WithdrawDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.merchantAccount = this.merchantAccountDetailInfoDTO.merchantAccount;
          this.form.merchantAccountId = this.merchantAccountDetailInfoDTO.merchantId;
          this.form.merchantName = this.merchantAccountDetailInfoDTO.merchantName;
          const formData = { ...this.form };
          console.log(this.form);
          this.$http.post(apis.withdrawal, formData).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("提现申请成功！");
              this.closeDialog();
              this.$parent.getMerchantInfo();
            }
          });
        }
      });
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      if (data) {
        this.form.id = data.id;
      }
    },
    openDialog() {
      this.isShow = true;
    },
  },
};
</script>
<style lang="less" scoped>
.content-label {
  height: 45px;
  .content-label-text {
    color: red;
  }
  .content-label-text1 {
    margin-left: 10px;
  }
  .content-label-text2 {
    color: #40a6f9;
    cursor: pointer;
  }
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
.line {
  width: 100%;
  height: 0;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
