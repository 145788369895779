import PageClass from '@/unit/pageClass'
import uploadHttp from '@/uploadHttp'
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      selectedArr: [],
    }
  },
  computed: {
    ...mapState(['USERTYPE', 'USERNAME', 'SYSTEMID', 'NAME', 'USERID', 'DROPDOWNBOX', 'buttonAuth', 'authority', 'LOGODIAPLAY', 'LOGOSWITCH', 'eventId', 'TOKEN', 'venueId']),
  },
  methods: {
    ...mapMutations(['setButtonAuth', 'setDropDownbox', 'setName', 'setUserId', 'setUserType', 'setSystemId', 'setUserName', 'setAuthority', 'setEventId']),
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$emit('update:show', false)
      this.$emit('closeDialog', false)
    },
    /**
     * @function 打开弹窗调用的方法,一般是不用写
     */
    openDialog() {
      console.log(`我打开了弹窗`)
      if (this.searchWidthOpen) {
        this.searchEvent()
      }
    },
    /**
     * @function 回显的时候获取数据
     * @param {String} url 获取回显的地址
     * @param {Object} data 请求接口的数据
     */
    getDataQuery(url, data) {
      // 在这里获取回显的数据
      console.log(url)
      console.log(data)
      console.info(`在这里请求数据并赋值`)
    },
    /**
     * @function 点击搜索按钮触发的事件，对分页进行初始化，然后获取数据
     */
    searchEvent() {
      this.form = { ...this.form, ...new PageClass() }
      console.log('点击了搜索按钮')
      // 这里执行搜索操作
      this.searchData()
    },
    /**
     * @function 分页尺寸修改方法
     * @param {Number} pageSize 修改后的分页大小
     */
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize
      // 业务问题，每次修改分页大小后要不要重新返回第一页
      this.form.pageNum = 1
      this.searchData()
    },
    /**
     * @function 当前页修改方法
     * @param {Number} pageNum 修改后的当前页
     */
    handleCurrentChange(pageNum) {
      this.form.pageNum = pageNum
      this.searchData()
    },
    /**
     * @function 表格勾选后赋值给变量
     * @param {Array} arr 表格选中时所有选中的数组
     */
    handleSelectionChange(arr) {
      this.selectedArr = arr
    },
    /* 导入上传 */
    uploadHttpDefault(e, hasDirectory = false) {
      uploadHttp(e, hasDirectory)
    },
    /* 删除数组中制定函数 */
    delete(arr, item1) {
      // eslint-disable-next-line no-shadow
      const index = arr.findIndex(item => item.value === item1)
      arr.splice(index, 1)
    },
  },
}
