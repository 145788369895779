/* eslint-disable */

/**
 * 格式化 后端 结构信息并递归生成层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  console.log("routerMap" ,routerMap)
  return routerMap.map((item) => {
    const currentRouter = {
      // 路由地址 动态拼接生成如 /dashboard/workplace
      path: `${parent && parent.path || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 隐藏菜单
      hidden: item.hidden || false,
      // 该路由对应页面的 组件
      // component: constantRouterComponents[item.component || item.key] || loadView(item.component),
      hideChildrenInMenu: item.hideChildrenInMenu || false,
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: { title: item.title, icon: item.icon || undefined, hiddenHeaderContent: item.hiddenHeaderContent || false }
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    currentRouter.path = currentRouter.path.replace('//', '/')
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    
    return currentRouter
  })
}

export function buildtree(list, arr, parentId) {
  list.forEach((item) => {
    if (item.parentId === parentId) {
      const child = {
        title: item.menuName,
        key: item.menuKey,
        icon: item.icon,
        hidden: item.visible === '1',
        component: item.component,
        children: [],
      }
      buildtree(list, child.children, item.menuId)
      if (child.children.length === 0) {
        delete child.children
        // delete child.component
      }
      arr.push(child)
    }
  })
}
