<!--
 * @Author: your name
 * @Date: 2021-09-26 15:52:06
 * @LastEditTime: 2021-09-27 14:38:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\dialog\errorDialog.vue
-->
<template>
    <div>
        <el-dialog
        :visible.sync="show"
        @open="openDialog"
        :before-close="closeDialog"
        :show-close="false"
        :close-on-click-modal="false"
        :modal="false"
        center
        width="600px"
        top="300px">
            <div class="container">
                <div class="sort">
                    <i class="el-icon-circle-close"></i>
                </div>
                <div class="errorMsg">
                    <div class="title">出错了！</div>
                    <div>{{ msg }}</div>
                </div>
            </div>
            <i class="el-icon-close close-error" @click="closeDialog"></i>
        </el-dialog>
    </div>
</template>

<script>
import mixin from '@/mixins/dialogMixin'

export default {
    mixins: [mixin],
    props: {
        msg: {
            required: true,
            type: String,
        },
        show: {
            required: true,
            type: Boolean,
        },
        Language: {
            required: false,
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
        };
    },

    mounted() {},

    methods: {
        closeDialog() {
            this.$emit('closeDialog', false, '');
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog{
    background-color: #FFF1F0;
    border: 1px solid #FFA39E;
    padding: 16px 24px;
}
/deep/.el-dialog__header{
    border-bottom: 0px;
    padding: 0;
}
/deep/.el-dialog__body{
    padding: 0 !important;
}
/deep/.el-dialog__footer{
    padding: 30px 0 !important;
}
.container{
    display: flex;
    .sort{
        margin-right: 16px;
        i{
            color: #FFA39E;
            font-size: 24px;
        }
    }
    .errorMsg{
        .title{
            height: 24px;
            line-height: 24px;
            font-size: 16px;
            font-weight: Medium;
        }
    }
}
.close-error{
    position: absolute;
    top: 14px;
    right: 16px;
    font-size:12px;
}
</style>
