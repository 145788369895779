<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${flag ? $t('validateSuccess') : $t('validateFalied')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      append-to-body
      width="400px">
      <div class="dialog-wrap">
        <p>验证码: {{valiCode}}</p>
        <p v-if="flag">验证时间: {{valiTime}}</p>
        <p v-else>失败原因： {{errorResult}}</p>
        <div class="remark">
          <div>备注：</div>
          <div><el-input v-model="remark" type="textarea" maxlength="100" rows="6"></el-input></div>
        </div>
      </div>
      <template slot="footer">
        <el-button @click="sure">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    flag: {
      required: true,
      type: Boolean,
    },
    valiCode: {
      required: true,
      type: String,
    },
    valiTime: {
      required: false,
      type: String,
    },
    errorResult: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      remark: '',
    }
  },
  mounted() {
    document.onkeydown = () => {
      const key = window.event.keyCode
      if (key === 13) {
        if (this.show) {
          setTimeout(() => {
            this.sure()
          }, 300)
        }
      }
    }
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      console.log('我初始化了')
      this.remark = ''
    },
    sure() {
      this.$http.post(apis.updateOrderDetailRemark, { id: this.id, remark: this.remark, changeType: this.type }).then((res) => {
        if (res.data.code === 0) {
          this.closeDialog()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.remark {
  font-size: 16px;
  font-weight: 700;
  display: flex;
}
</style>
