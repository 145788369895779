/*
    用于在左侧菜单发生变动时检查菜单中的路径前端是否都存在
*/

import Vue from 'vue'
import store from '@/store';
import { mapState } from "vuex";
import { each, get } from 'lodash'
import router from '@/router';

/**
 * 遍历树结构的函数
 * @param {Tree} tree
 * @param {function} callback
 */
function traverse(tree, callback) {
    if (!tree) {
        return
    }

    callback(tree)

    if (tree.children) {
        each(tree.children, (e) => {
            traverse(e, callback);
        });
    }
}

export default new Vue({
    store,

    computed: {
        ...mapState(['authority']),
    },

    watch: {
        authority() {
            this.checkPathExist();
        },
    },

    created() {
        this.checkPathExist();
    },
    methods: {
        checkPathExist() {
            const noExistpath = []

            each(this.authority, (menu) => {
                traverse(menu, (e) => {
                    const arr = router.getMatchedComponents(e.path);

                    if (arr.length === 0) {
                        noExistpath.push({
                            title: get(e, 'meta.title'),
                            path: e.path,
                        })

                    }
                })
            })

            if (noExistpath.length > 0) {
                console.error('【警告】菜单配置中含有以下不存在的路由，请检查路由配置：')
                console.table(noExistpath)
            }
        },
    },
});
