import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '@/store/index'
import apis from '@/apis'
import http from '@/plugins/axios'


Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '登录', keepAlive: true },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: '首页', keepAlive: true },
    children: [
      {
        path: 'simple',
        name: 'simple',
        component: () => import('@/views/simple/index.vue'),
        children: [
          {
            path: 'demo',
            name: 'demo',
            component: () => import('@/views/simple/demo/index.vue'),
            meta: { title: 'DEMO', keepAlive: true },
          },
          {
            path: 'login',
            name: 'login',
            component: () => import('@/views/simple/login/index.vue'),
          },
        ],
      },
      {
        path: 'system',
        name: 'system',
        component: () => import('@/views/system/index.vue'),
        children: [
          {
            path: 'user',
            name: 'user',
            component: () => import('@/views/system/user/indexNew.vue'),
            meta: { title: '用户管理', keepAlive: true },
          },
          {
            path: 'role',
            name: 'role',
            component: () => import('@/views/system/role/index.vue'),
            meta: { title: '角色管理', keepAlive: true },
          },
          {
            path: 'permission',
            name: 'permission',
            component: () => import('@/views/system/resource/index.vue'),
            meta: { title: '菜单管理', keepAlive: true },
          },
          {
            path: 'department',
            name: 'department',
            component: () => import('@/views/system/department/index.vue'),
            meta: { title: '部门管理', keepAlive: true },
          },
          {
            path: 'dictionaries',
            name: 'dictionaries',
            component: () => import('@/views/system/dictionaries/index.vue'),
            meta: { title: '字典管理', keepAlive: true },
          },
          {
            path: 'file',
            name: 'file',
            component: () => import('@/views/system/file/index.vue'),
            meta: { title: '文件管理', keepAlive: true },
          },
          {
            path: 'area',
            name: 'area',
            component: () => import('@/views/system/area/index.vue'),
            meta: { title: '地区管理', keepAlive: true },
          },
          {
            path: 'parameter',
            name: 'parameter',
            component: () => import('@/views/system/parameter/index.vue'),
            meta: { title: '参数管理', keepAlive: true },
          },
          {
            path: 'fileManage',
            name: 'fileManage',
            component: () => import('@/views/system/fileManage/index.vue'),
            meta: { title: '文件管理', keepAlive: true },
          },
          {
            path: 'memberManage',
            name: 'memberManage',
            component: () => import('@/views/system/memberManage/index.vue'),
            meta: { title: '平台会员管理', keepAlive: true },
          },
        ],
      },
      {
        path: 'log',
        name: 'log',
        component: () => import('@/views/log/index.vue'),
        children: [
          {
            path: 'operation',
            name: 'operation',
            component: () => import('@/views/log/operation/index.vue'),
            meta: { title: '操作日志', keepAlive: true },
          },
          {
            path: 'login',
            name: 'login',
            component: () => import('@/views/log/login/index.vue'),
            meta: { title: '登录日志', keepAlive: true },
          },
        ],
      },
      {
        path: 'act',
        name: 'act',
        component: () => import('@/views/act/index.vue'),
        meta: { title: '流程管理', keepAlive: true },
        children: [
          {
            path: 'businessList',
            name: 'businessList',
            component: () => import('@/views/act/businessList/index.vue'),
            meta: { title: "我的申请", keepAlive: true },
          },
          {
            path: 'tasking',
            name: 'tasking',
            component: () => import('@/views/act/tasking/index.vue'),
            meta: { title: "我的代办", keepAlive: true },
          },
          {
            path: 'taskdone',
            name: 'taskdone',
            component: () => import('@/views/act/taskdone/index.vue'),
            meta: { title: "我的已办", keepAlive: true },
          },
          {
            path: 'modelList',
            name: 'modelList',
            component: () => import('@/views/act/modelList/index.vue'),
            meta: { title: "模型管理", keepAlive: true },
          },
          {
            path: 'procDef',
            name: 'procDef',
            component: () => import('@/views/act/procDef/index.vue'),
            meta: { title: "流程定义", keepAlive: true },
          },
          {
            path: 'procInstRuns',
            name: 'procInstRuns',
            component: () => import('@/views/act/procInstRuns/index.vue'),
            meta: { title: "运行中流程", keepAlive: true },
          },
          {
            path: 'procInstFinished',
            name: 'procInstFinished',
            component: () => import('@/views/act/procInstFinished/index.vue'),
            meta: { title: "结束的流程", keepAlive: true },
          },
          {
            path: 'procDefNode',
            name: 'procDefNode',
            component: () => import('@/views/act/procDef/procDefNode.vue'),
            meta: { title: "流程节点配置", keepAlive: true },
          },
        ],
      },
      {
        path: 'merchantManage',
        name: 'merchantManage',
        component: () => import('@/views/merchantManage/index.vue'),
        children: [
          {
            path: 'accountManage',
            name: 'accountManage',
            component: () => import('@/views/merchantManage/accountManage/index.vue'),
            meta: { title: '账号管理', keepAlive: true },
          },
        ],
      },
      {
        path: 'intelligentMonitoring',
        name: 'intelligentMonitoring',
        component: () => import('@/views/intelligentMonitoring/index.vue'),
        children: [
          {
            path: 'IntelligentManger',
            name: 'IntelligentManger',
            component: () => import('@/views/intelligentMonitoring/IntelligentManger/index.vue'),
            meta: { title: '设备管理', keepAlive: true },
          },
          {
            path: 'equipmentMonitoring',
            name: 'equipmentMonitoring',
            component: () => import('@/views/intelligentMonitoring/equipmentMonitoring/index.vue'),
            meta: { title: '设备监控', keepAlive: true },
          },
          {
            path: 'receiverAndUnit',
            name: 'receiverAndUnit',
            component: () => import('@/views/intelligentMonitoring/receiverAndUnit/index.vue'),
            meta: { title: '监控图表', keepAlive: true },
          },
          {
            path: 'supplier',
            name: 'supplier',
            component: () => import('@/views/intelligentMonitoring/supplier/index.vue'),
            meta: { title: '告警记录', keepAlive: true },
          },
          {
            path: 'waring',
            name: 'waring',
            component: () => import('@/views/intelligentMonitoring/waring/index.vue'),
            meta: { title: '告警规则配置', keepAlive: true },
          },
        ],
      },
      {
        path: 'bigScreen',
        name: 'bigScreen',
        component: () => import('@/views/bigScreen/index.vue'),
        meta: { title: '大屏服务', keepAlive: true },
      },
      // 新版
      {
        path: 'venueOperation',
        name: 'venueOperation',
        component: () => import('@/views/venueOperation/index.vue'),
        meta: { title: '场馆运营', keepAlive: true },
        children: [
          {
            path: 'order',
            name: 'order',
            component: () => import('@/views/venueOperation/order/index.vue'),
            meta: { title: '开单', keepAlive: true },
          },
          {
            path: 'venue',
            name: 'venue',
            component: () => import('@/views/venueMana/venue/index.vue'),
            meta: { title: '我的场馆', keepAlive: true },
          },
          {
            path: 'settlement',
            name: 'settlement',
            component: () => import('@/views/venueOperation/order/compoments/orderSettlement.vue'),
            meta: { title: '支付', keepAlive: true },
          },
          {
            path: 'ticketRecord',
            name: 'ticketRecord',
            component: () => import('@/views/venueOperation/ticketRecord/index.vue'),
            meta: { title: '验票记录', keepAlive: true },
          },
          {
            path: 'orderRecord',
            name: 'orderRecord',
            component: () => import('@/views/venueOperation/orderRecord/index.vue'),
            meta: { title: '订单管理', keepAlive: true },
          },
          {
            path: 'customer',
            name: 'customer',
            component: () => import('@/views/venueOperation/customer/index.vue'),
            meta: { title: '客户管理', keepAlive: true },
          },
          {
            path: 'course',
            name: 'course',
            component: () => import('@/views/venueMana/course/index.vue'),
            meta: { title: '课程管理', keepAlive: true },
          },
          {
            path: 'coach',
            name: 'coach',
            component: () => import('@/views/venueMana/coach/index.vue'),
            meta: { title: '教练管理', keepAlive: true },
          },
          {
            path: 'member',
            name: 'member',
            component: () => import('@/views/venueOperation/member/index.vue'),
            meta: { title: '会员管理', keepAlive: true },
          },
          {
            path: 'menberCard',
            name: 'menberCard',
            component: () => import('@/views/venueOperation/menberCard/index.vue'),
            meta: { title: '开卡', keepAlive: true },
          },
          {
            path: 'onlineVideo',
            name: 'onlineVideo',
            component: () => import('@/views/venueMana/onlineVideo/index.vue'),
            meta: { title: '视频课程', keepAlive: true },
          },
          {
            path: 'venueReservation',
            name: 'venueReservation',
            component: () => import('@/views/venueMana/venueReservation/index.vue'),
            meta: { title: '预订记录', keepAlive: true },
          },
          {
            path: 'classification',
            name: 'classification',
            component: () => import('@/views/venueMana/classification/index.vue'),
            meta: { title: '线上视频分类管理', keepAlive: true },
          },
          {
            path: 'addVideo',
            name: 'addVideo',
            component: () => import('@/views/venueMana/addVideo/index.vue'),
            meta: { title: '新建视频' },
          },
          {
            path: 'editVideo',
            name: 'editVideo',
            component: () => import('@/views/venueMana/addVideo/index.vue'),
            meta: { title: '编辑视频' },
          },
        ],
      },
      {
        path: 'settlementManage',
        name: 'settlementManage',
        component: () => import('@/views/settlementManage/index.vue'),
        meta: { title: '结算管理', keepAlive: true },
        children: [
          {
            path: 'orderManage',
            name: 'orderManage',
            component: () => import('@/views/settlementManage/orderManage/index.vue'),
            meta: { title: '订单管理', keepAlive: true },
          },
          {
            path: 'withdrawalManage',
            name: 'withdrawalManage',
            component: () => import('@/views/settlementManage/withdrawal/index.vue'),
            meta: { title: '提现管理', keepAlive: true },
          },
          {
            path: 'availableDetail',
            name: 'availableDetail',
            component: () => import('@/views/settlementManage/fundDetail/index.vue'),
            meta: { title: '可用明细', keepAlive: true },
          },
          {
            path: 'freezeDetail',
            name: 'freezeDetail',
            component: () => import('@/views/settlementManage/fundDetail/index.vue'),
            meta: { title: '冻结明细', keepAlive: true },
          },
          {
            path: 'fundDetail',
            name: 'fundDetail',
            component: () => import('@/views/settlementManage/fundDetail/index.vue'),
            meta: { title: '资金明细', keepAlive: true },
          },
        ],
      },
      // 商品及服务
      {
        path: 'venueMana',
        name: 'VenueMana',
        component: () => import('@/views/venueMana/index.vue'),
        meta: { title: '场馆管理', keepAlive: true },
        children: [
          {
            path: 'service',
            name: 'Service',
            component: () => import('@/views/venueMana/service/index.vue'),
            meta: { title: '商品及服务', keepAlive: true },
          },
          {
            path: 'venueManage',
            name: 'venueManage',
            component: () => import('@/views/merchantManage/accountManage/index.vue'),
            meta: { title: '账号管理', keepAlive: true },
          },
          // {
          //   path: 'venue',
          //   name: 'venue',
          //   component: () => import('@/views/venueMana/venue/index.vue'),
          //   meta: { title: '我的场馆', keepAlive: true },
          // },
          {
            path: 'venueRole',
            name: 'VenueRole',
            component: () => import('@/views/venueMana/venueRole/index.vue'),
            meta: { title: '场馆权限', keepAlive: true },
          },
          {
            path: 'baseVenue',
            name: 'baseVenue',
            component: () => import('@/views/venueMana/baseVenue/index.vue'),
            meta: { title: '基础场馆', keepAlive: true },
          },
          {
            path: 'venueVideo',
            name: 'venueVideo',
            component: () => import('@/views/venueMana/venueVideo/index.vue'),
            meta: { title: '场馆视频' },
          },
        ],
      },
      // 赛事管理
      {
        path: 'matchManage',
        name: 'matchManage',
        component: () => import('@/views/matchManage/index.vue'),
        meta: { title: '体育赛事', keepAlive: true },
        children: [
          {
            path: 'competitionInvitation',
            name: 'competitionInvitation',
            component: () => import('@/views/competitionInvitation/index.vue'),
            meta: { title: '赛事邀请' },
          },
          {
            path: 'match',
            name: 'match',
            component: () => import('@/views/venueMana/match/index.vue'),
            meta: { title: '赛事管理' },
          },
        ],
      },
      // 活动管理（音乐节）
      {
        path: 'concert',
        name: 'concert',
        component: () => import('@/views/venueMana/concert/index.vue'),
        meta: { title: '活动管理', keepAlive: true },
        children: [
        ],
      },
      // 成绩查询
      {
        path: 'grade',
        name: 'grade',
        component: () => import('@/views/venueMana/grade/index.vue'),
        meta: { title: '成绩查询', keepAlive: true },
        children: [
        ],
      },
      //官网配置
      {
        path: 'website',
        name: 'website',
        component: () => import('@/views/website/index.vue'),
        meta: { title: '官网配置', keepAlive: true },
        children: [
          {
            path: 'websiteInformation',
            name: 'websiteInformation',
            component: () => import('@/views/website/information/index.vue'),
            meta: { title: '官网资讯配置', keepAlive: true },
          },
          {
            path: 'permission',
            name: 'permission',
            component: () => import('@/views/website/resource/index.vue'),
            meta: { title: '菜单管理', keepAlive: true },
          },
        ],
      },
      // 赛事管理
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/venueMana/information/index.vue'),
        meta: { title: '资讯管理', keepAlive: true },
        children: [
        ],
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/views/venueMana/service/component/setting.vue'),
        meta: { title: '高级设置', keepAlive: true },
      },
      // 赛事报名情况
      {
        path: 'application',
        name: 'application',
        component: () => import('@/views/venueMana/match/component/application.vue'),
        meta: { title: '报名情况', keepAlive: true },
      },
      // 课程报名情况
      {
        path: 'courseApplication',
        name: 'courseApplication',
        component: () => import('@/views/venueMana/course/component/application.vue'),
        meta: { title: '报名情况', keepAlive: true },
      },
      // 视频课程详情
      {
        path: 'videoCourse',
        name: 'videoCourse',
        component: () => import('@/views/venueMana/course/component/videoDetail.vue'),
        meta: { title: '视频课程详情', keepAlive: true },
      },
      // 便民管理
      {
        path: 'convenienceService',
        name: 'convenienceService',
        component: () => import('@/views/convenience/first.vue'),
        meta: { title: '便民服务', keepAlive: true },
        children: [
          {
            path: 'convenience',
            name: 'convenience',
            component: () => import('@/views/convenience/index.vue'),
            meta: { title: '便民服务管理', keepAlive: true },
          },
          {
            path: 'contentClassification',
            name: 'contentClassification',
            component: () => import('@/views/basicConfig/contentClassification/index.vue'),
            meta: { title: '内容分类管理', keepAlive: true },
          },
        ],
      },
      // 必游景点管理
      {
        path: 'mustSeeSpot',
        name: 'mustSeeSpot',
        component: () => import('@/views/mustSeeSpot/index.vue'),
        meta: { title: '必游景点管理', keepAlive: true },
      },
      // VR管理
      {
        path: 'vrManage',
        name: 'vrManage',
        component: () => import('@/views/vrManage/index.vue'),
        meta: { title: 'VR管理', keepAlive: true },
      },
      //赛事邀请
      // {
      //   path: 'competitionInvitation',
      //   name: 'competitionInvitation',
      //   component: () => import('@/views/competitionInvitation/index.vue'),
      //   meta: { title: '赛事邀请', keepAlive: true },
      // },

      // 报表管理
      {
        path: 'report',
        name: 'report',
        component: () => import('@/views/report/index.vue'),
        meta: { title: '报表管理', keepAlive: true },
        children: [
          {
            path: 'matchDailyReport',
            name: 'matchDailyReport',
            component: () => import('@/views/report/matchDailyReport/matchDailyReport.vue'),
            meta: { title: '赛事日报表', keepAlive: true },
          },
          {
            path: 'matchDailyDetailReport',
            name: 'matchDailyDetailReport',
            component: () => import('@/views/report/matchDailyDetailReport/matchDailyDetailReport.vue'),
            meta: { title: '赛事日详细报表', keepAlive: true },
          },
          {
            path: 'scoreQuery',
            name: 'scoreQuery',
            component: () => import('@/views/report/scoreQuery/scoreQuery.vue'),
            meta: { title: '成绩查询', keepAlive: true },
          },
          {
            path: 'concertQuery',
            name: 'concertQuery',
            component: () => import('@/views/report/concertQuery/concertQuery.vue'),
            meta: { title: '售票情况', keepAlive: true },
          },
          {
            path: 'wzDailyReport',
            name: 'wzDailyReport',
            component: () => import('@/views/report/wzDailyReport/wzDailyReport.vue'),
            meta: { title: '物资领取日报表', keepAlive: true },
          },
          {
            path: 'wzApplyInfoReport',
            name: 'wzApplyInfoReport',
            component: () => import('@/views/report/wzApplyInfoReport/wzApplyInfoReport.vue'),
            meta: { title: '电子签名报表', keepAlive: true },
          },
          {
            path: 'statisticalReport',
            name: 'statisticalReport',
            component: () => import('@/views/report/statisticalReport/statisticalReport.vue'),
            meta: { title: '预约日报表', keepAlive: true },
          },
          {
            path: 'tickReport',
            name: 'tickReport',
            component: () => import('@/views/report/tickReport/tickReport.vue'),
            meta: { title: '票务日报表', keepAlive: true },
          },
          {
            path: 'courseReport',
            name: 'courseReport',
            component: () => import('@/views/report/courseReport/courseReport.vue'),
            meta: { title: '课程日报表', keepAlive: true },
          },
          {
            path: 'matchReport',
            name: 'matchReport',
            component: () => import('@/views/report/matchReport/matchReport.vue'),
            meta: { title: '赛事报表', keepAlive: true },
          },
          {
            path: 'financialStatements',
            name: 'financialStatements',
            component: () => import('@/views/financialStatements/index.vue'),
            meta: { title: '财务基础报表', keepAlive: true },
          },
        ],
      },
      // 健身指导
      {
        path: 'fitnessInstructionManage',
        name: 'fitnessInstructionManage',
        component: () => import('@/views/fitnessInstructionManage/index.vue'),
        meta: { title: '健身指导', keepAlive: true },
        children: [
          {
            path: 'fitnessInstruction',
            name: 'fitnessInstruction',
            component: () => import('@/views/fitnessInstructionManage/fitnessInstruction/index.vue'),
            meta: { title: '健身指导管理', keepAlive: true },
          },
          {
            path: 'baseMana',
            name: 'baseMana',
            component: () => import('@/views/fitnessInstructionManage/baseMana/index.vue'),
            meta: { title: '内容分类管理', keepAlive: true },
          },
        ],
      },
      // 健身设施管理
      {
        path: 'fitnessFacilities',
        name: 'fitnessFacilities',
        component: () => import('@/components/router-view-only.vue'),
        meta: { title: '健身设施', keepAlive: true },
        children: [{
          path: 'facilityManagement',
          name: 'facilityManagement',
          component: () => import('@/views/fitnessFacilities/facilityManagement/facilityManagement.vue'),
          meta: { title: '健身设施管理', keepAlive: true },
        }, {
          path: 'contentClassification',
          name: 'contentClassification',
          component: () => import('@/views/fitnessFacilities/contentClassification/index.vue'),
          meta: { title: '内容分类管理', keepAlive: true },
        }, {
          path: 'repairReport',
          name: 'repairReport',
          component: () => import('@/views/fitnessFacilities/repairReport/repairReport.vue'),
          meta: { title: '器材维修上报', keepAlive: true },
        }, {
          path: 'deviceTypeManger',
          name: 'deviceTypeManger',
          component: () => import('@/views/fitnessFacilities/deviceTypeManger/deviceTypeManger.vue'),
          meta: { title: '健身器材类型管理', keepAlive: true },
        },
        ],
      },
      //优惠券
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import('@/views/coupon/index.vue'),
        meta: { title: '优惠券', keepAlive: true },
      },
      {
        path: 'physique',
        name: 'physique',
        component: () => import('@/views/venueMana/physique/index.vue'),
        meta: { title: '体质监测', keepAlive: true },
      },
      // 智能设备管理
      {
        path: 'IntelligentDeviceManage',
        name: 'IntelligentDeviceManage',
        component: () => import('@/views/IntelligentDeviceManage/index.vue'),
        meta: { title: '智能设备', keepAlive: true },
        children: [{
          path: 'deviceHome',
          name: 'deviceHome',
          component: () => import('@/views/IntelligentDeviceManage/deviceHome/index.vue'),
          meta: { title: '智能设备首页', keepAlive: true },
        }, {
          path: 'devicedeAlarmRecord',
          name: 'devicedeAlarmRecord',
          component: () => import('@/views/IntelligentDeviceManage/deviceList/devicedeAlarmRecord/index.vue'),
          meta: { title: '设备告警记录', keepAlive: true },
        }, {
          path: 'highTemperAlarmRecord',
          name: 'highTemperAlarmRecord',
          component: () => import('@/views/IntelligentDeviceManage/deviceList/highTemperAlarmRecord/index.vue'),
          meta: { title: '人体高温告警记录', keepAlive: true },
        }, {
          path: 'deviceList',
          name: 'deviceList',
          component: () => import('@/views/IntelligentDeviceManage/deviceList/index.vue'),
          meta: { title: '设备列表', keepAlive: true },
          children: [{
            path: 'gate',
            name: 'gate',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/gate/index.vue'),
            meta: { title: '闸机', keepAlive: true },
          }, {
            path: 'accessRecord',
            name: 'accessRecord',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/gate/component/accessRecord.vue'),
            meta: { title: '出入记录', keepAlive: true },
          }, {
            path: 'pos',
            name: 'pos',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/pos/index.vue'),
            meta: { title: '小票机', keepAlive: true },
          }, {
            path: 'passengerFlowCamera',
            name: 'passengerFlowCamera',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/passengerFlowCamera/index.vue'),
            meta: { title: '客流摄像头', keepAlive: true },
          }, {
            path: 'passengerFlow',
            name: 'passengerFlow',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/passengerFlowCamera/component/passengerFlow.vue'),
            meta: { title: '客流记录', keepAlive: true },
          }, {
            path: 'liveCamera',
            name: 'liveCamera',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/liveCamera/index.vue'),
            meta: { title: '直播摄像头', keepAlive: true },
          }, {
            path: 'internetDevice',
            name: 'internetDevice',
            component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/index.vue'),
            meta: { title: '物联网设备', keepAlive: true },
            children: [{
              path: 'IOTGateway',
              name: 'IOTGateway',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/IOTGateway/index.vue'),
              meta: { title: '物联网关', keepAlive: true },
            }, {
              path: 'IntelligentSwitchGateway',
              name: 'IntelligentSwitchGateway',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/IntelligentSwitchGateway/index.vue'),
              meta: { title: '智能开关网关', keepAlive: true },
            }, {
              path: 'IntelligentSwitchNum',
              name: 'IntelligentSwitchNum',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/IntelligentSwitchGateway/component/IntelligentSwitchNum.vue'),
              meta: { title: '智能开关数', keepAlive: true },
            }, {
              path: 'smartLight',
              name: 'smartLight',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/smartLight/index.vue'),
              meta: { title: '智能灯', keepAlive: true },
            }, {
              path: 'switchRecord',
              name: 'switchRecord',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/smartLight/component/switchRecord.vue'),
              meta: { title: '开关记录', keepAlive: true },
            }, {
              path: 'airDetector',
              name: 'airDetector',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/airDetector/index.vue'),
              meta: { title: '空气检测仪', keepAlive: true },
            }, {
              path: 'weatherDetector',
              name: 'weatherDetector',
              component: () => import('@/views/IntelligentDeviceManage/deviceList/internetDevice/weatherDetector/index.vue'),
              meta: { title: '气象检测仪', keepAlive: true },
            },
            ],
          },
          ],
        },
        ],
      },
      {
        path: 'liveBroadcast',
        name: 'liveBroadcast',
        component: () => import('@/views/liveBroadcast/index.vue'),
        meta: { title: '视频直播', keepAlive: true },
        children: [{
          path: 'live',
          name: 'live',
          component: () => import('@/views/liveBroadcast/live.vue'),
          meta: { title: '直播首页', keepAlive: false },
        }, {
          path: 'shareRecord',
          name: 'shareRecord',
          component: () => import('@/views/liveBroadcast/shareRecord.vue'),
          meta: { title: '分享记录', keepAlive: true },
        }, {
          path: 'editVideoRecord',
          name: 'editVideoRecord',
          component: () => import('@/views/liveBroadcast/editVideoRecord.vue'),
          meta: { title: '剪辑视频记录' },
        }],
      },
      {
        path: 'statisticsReport',
        name: 'statisticsReport',
        component: () => import('@/views/statisticsReport/index.vue'),
        meta: { title: '统计报表', keepAlive: true },
        children: [{
          path: 'report',
          name: 'report',
          component: () => import('@/views/statisticsReport/report.vue'),
          meta: { title: '场馆收入统计', keepAlive: false },
        }, {
          path: 'merchant',
          name: 'merchant',
          component: () => import('@/views/statisticsReport/merchant.vue'),
          meta: { title: '商户收入统计', keepAlive: false },
        },
        {
          path: 'platformDetail',
          name: 'platformDetail',
          component: () => import('@/views/statisticsReport/platformDetail.vue'),
          meta: { title: '场馆收入订单明细', keepAlive: false },
        },
        {
          path: 'platform',
          name: 'platform',
          component: () => import('@/views/statisticsReport/platform.vue'),
          meta: { title: '平台收入统计', keepAlive: false },
        },
        ],
      },
      // 体质检测点
      {
        path: 'physiqueDetector',
        name: 'physiqueDetector',
        component: () => import('@/views/physiqueDetector/index.vue'),
        meta: { title: '体质检测仪', keepAlive: true },
      },
      // 体质检测报告
      {
        path: 'phyExaminReport',
        name: 'phyExaminReport',
        component: () => import('@/views/phyExaminReport/index.vue'),
        meta: { title: '体质检测报告', keepAlive: true },
      },
      // 产品中心
      {
        path: 'productCenter',
        name: 'productCenter',
        component: () => import('@/views/productCenter/index.vue'),
        meta: { title: '产品中心', keepAlive: true },
        children: [
          {
            path: 'vipCardManagement',
            name: 'vipCardManagement',
            component: () => import('@/views/productCenter/vipCardManagement/vipCardManagement.vue'),
            meta: { title: '会员卡管理', keepAlive: true },
          }, {
            path: 'productSalesActivities',
            name: 'productSalesActivities',
            component: () => import('@/views/productCenter/productSalesActivities/index.vue'),
            meta: { title: '产品销售活动', keepAlive: true },
          }, {
            path: 'labelManagement',
            name: 'labelManagement',
            component: () => import('@/views/productCenter/labelManagement/index.vue'),
            meta: { title: '标签管理', keepAlive: true },
          }, {
            path: 'programManagement',
            name: 'programManagement',
            component: () => import('@/views/productCenter/programManagement/index.vue'),
            meta: { title: '栏目管理', keepAlive: true },
          },
        ],
      },
      // 闸机管理
      {
        path: 'gateManagement',
        name: 'gateManagement',
        component: () => import('@/views/gateManagement/index.vue'),
        children: [
          {
            path: 'gateList',
            name: 'gateList',
            component: () => import('@/views/gateManagement/gateList/index.vue'),
            meta: { title: '闸机管理', keepAlive: true },
          },
          {
            path: 'gateRecord',
            name: 'gateRecord',
            component: () => import('@/views/gateManagement/gateRecord/index.vue'),
            meta: { title: '闸机记录', keepAlive: true },
          },
        ],
      },
    ],
  },
];
const getDict = () => {
  if (store.state.TOKEN) {
    http.post(apis.SysDictList).then((res) => {
      if (res.data.code === 0) {
        const { data } = res
        const newDict = { ...store.state.DROPDOWNBOX, ...data.rows }
        store.commit('setDropDownbox', newDict)
        sessionStorage.setItem('DROPDOWNBOX', JSON.stringify(newDict))
      }
    })
  }
}
const router = new VueRouter({
  routes,
});

// 判断是否有
router.beforeEach((to, from, next) => {
  getDict()
  if (to.name !== 'login') {
    if (!store.state.TOKEN && to.name !== 'login') {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
