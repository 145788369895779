<template>
  <div class="dialog-container">
    <el-dialog
      :title="$t('change_password')"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      width="50%">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('oldPassword')" prop="oldPassword">
              <el-input clearable v-model="form.oldPassword" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('newPassword')" prop="newPassword">
              <el-input clearable v-model="form.newPassword" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('confirmPassword')" prop="confirmPassword">
              <el-input clearable v-model="form.confirmPassword" type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import ChangeDialogClass from './changeDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new ChangeDialogClass('form'), // 实例化一个表单的变量
      rules: new ChangeDialogClass('rule', this.Language), // 实例化一个表单的规则
      resourceData: [],
      data: {},
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    ...mapActions({
      loginOut: 'loginOut',
    }),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.newPassword !== this.form.confirmPassword) {
            this.$message.error(`${this.$t('password_some')}`)
          } else {
            this.$http.post(apis.User_UpdatePassword, this.form).then((res) => {
              if (res.data.code === 0) {
                this.loginOut()
                this.$router.push('/login')
                this.closeDialog()
              }
            })
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
    },
  },
}
</script>
