<template>
  <div class="home">
    <div class="left_container">
      <!-- 侧边栏组件 -->
      <Aside :defaultMenu="this.$route.path" :authority="authority"></Aside>
    </div>
    <div class="top_container">
      <div class="banner-rapper">
        <div class="left">{{ $env.VUE_APP_TITLE }}</div>
        <div class="right">
          <el-dropdown :style="{ marginRight: '30px' }">
            <span class="el-dropdown-link">
              {{ $t("welcome") }}，{{ userName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="changePasswored"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item @click.native="loginOut">{{
                $t("loginOut")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-select
            class="venueId"
            v-model="venueIdCopy"
            @change="reloadPage"
            filterable
          >
            <el-option
              :value="item.id"
              :label="item.venueName"
              v-for="item in venueList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- tab栏组件 -->
      <Tab ref="tab" @changePassword="changeChangeDialog"></Tab>
      <keep-alive v-if="$route.path !== '/'">
        <router-view></router-view>
      </keep-alive>
      <div class="home-contaoner" v-if="$route.path === '/'">
        <div class="left-grid-wrap">
          <div class="banner" v-if="!isShow">
            <el-row :gutter="20">
              <el-col :span="14">
                <div class="banner-one">
                  <div class="banner-one-title">商户信息</div>
                  <el-form label-width="90px" :form="form">
                    <el-row class="detail detail-merchant">
                      <div class="detail-content merchant-content">
                        <el-form-item
                          label="商户ID:"
                          class="detail-content-label"
                          ><span class="detail-content-text">
                            {{ merchantAccountDetailInfoDTO.merchantId }}</span
                          >
                        </el-form-item></div
                      >
                      <div  class="detail-content">
                        <el-form-item
                          label="商户名称:"
                          class="detail-content-label"
                          ><span>
                            {{
                              merchantAccountDetailInfoDTO.merchantName
                            }}</span
                          >
                        </el-form-item></div
                      >
                    </el-row>
                    <el-row>
                      <el-col :span="12" class="detail-content">
                        <el-form-item
                          label="所属主体:"
                          class="detail-content-label"
                          ><span> {{ getSubject }}</span>
                        </el-form-item></el-col
                      >
                      <el-col :span="12">
                        <el-form-item class="detail-content-label"
                          ><el-button
                            type="primary"
                            size="mini"
                            @click.native="handleOpenMerchantInfo"
                            >详情</el-button
                          ></el-form-item
                        >
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="10">
                <div class="banner-two">
                  <div class="banner-one-title">账户信息</div>
                  <el-form label-width="100px" :form="form">
                    <el-row class="detail">
                      <div  class="detail-content">
                        <el-form-item
                          label="账户总金额:"
                          class="detail-content-label"
                          ><span class="detail-content-text">
                            {{
                              merchantAccountDetailInfoDTO.totalAmount || 0
                            }}元</span
                          >
                        </el-form-item>
                      </div>
                      <div  class="detail-btn"
                        ><el-button
                          type="primary"
                          size="mini"
                          @click="toFundDetail('fundDetail', 0)"
                          >明细</el-button
                        ></div
                      >
                    </el-row>
                    <el-row class="detail">
                      <div class="detail-content">
                        <el-form-item
                          label="冻结金额:"
                          class="detail-content-label"
                          ><span class="detail-content-text1">
                            {{
                              merchantAccountDetailInfoDTO.frozenAmount || 0
                            }}元</span
                          >
                        </el-form-item></div
                      >
                      <div  class="detail-btn"
                        ><el-button
                          type="primary"
                          size="mini"
                          @click="toFundDetail('freezeDetail',1)"
                          >明细</el-button
                        ></div
                      >
                    </el-row>
                    <el-row class="detail">
                      <div  class="detail-content">
                        <el-form-item
                          label="可用余额:"
                          class="detail-content-label"
                          ><span class="detail-content-text">
                            {{
                              merchantAccountDetailInfoDTO.availableAmount || 0
                            }}元</span
                          >
                        </el-form-item></div
                      >
                      <div  class="detail-btn"
                        ><el-button
                          type="primary"
                          size="mini"
                          @click="toFundDetail('availableDetail',2)"
                          >明细</el-button
                        ><el-button
                          type="primary"
                          size="mini"
                          @click="toWithdraw(true, true)"
                          >提现</el-button
                        ></div
                      >
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="green-passageway-wrap">
            <div class="title-wrap">
              <div class="title-wrap-left">
                <el-tabs
                  v-model="tabForm.venueOrderType"
                  @tab-click="handleClick"
                >
                  <el-tab-pane
                    v-for="(item, index) in typeList"
                    :key="index"
                    :label="item.label"
                    :name="item.value"
                  ></el-tab-pane>
                </el-tabs>
              </div>
              <div class="title-wrap-right">
                <i
                  class="el-icon-refresh"
                  style="font-size: 16px; margin-right: 10px"
                  @click="toRefresh"
                ></i>
                <span @click="toNext('orderRecord')" v-if="isDisabled('/venueOperation/orderRecord')"> more </span>
                <img
                  @click="toNext('orderRecord')"
                  v-if="isDisabled('/venueOperation/orderRecord')"
                  style="width: 15px"
                  src="@/assets/images/right.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <div class="icon-wrap">
              <el-table
                :header-cell-style="{ background: '#F4F6F9', color: '#242D41' }"
                :data="tableData"
                :height="tableHeight"
                style="width: 100%; margin-left: 7px"
                border
                ref="table"
                :row-class-name="tableRowClassName"
              >
                <el-table-column
                  prop="type"
                  label="类型"
                  width="180"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="info" label="消息内容" align="center">
                </el-table-column>
                <el-table-column
                  prop="time"
                  label="时间"
                  width="180"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="right-grid-wrap">
          <div class="right-grid-top">
            <div class="today-time">
              {{ timeArr }}
            </div>
          </div>
          <div class="right-grid-middle">
            <el-row>
              <el-col :span="16">
                <el-input
                  clearable
                  ref="customerInput"
                  v-model="form.qrCode"
                  placeholder="请输入您的验票码"
                  @keyup.enter.native="checkTick"
                ></el-input>
              </el-col>
              <!-- v-if="buttonAuth.includes('home:action')" -->
              <el-col :span="8" style="text-align: right">
                <el-button type="primary" @click="checkTick">验票</el-button>
              </el-col>
            </el-row>
            <el-row style="margin-top:20px;">
              <el-col :span="16">
                <el-input
                  clearable
                  v-model="form.cardNo"
                  placeholder="请输入会员卡卡号"
                  @keyup.enter.native="checkCard"
                ></el-input>
              </el-col>
              <el-col :span="8" style="text-align: left">
                <el-button type="primary" @click="checkCard">会员卡入场</el-button>
              </el-col>
            </el-row>
            <el-row style="margin-top:20px;">
              <el-col :span="24">
                <el-input
                  clearable
                  v-model="form.telephone"
                  placeholder="请输入手机号"
                  @input="changetTel"
                ></el-input>
                <!-- <div v-if="telBox.length > 0" class="telBox">
                  <div v-for="(item, index) in telBox" :key="index" @click="telTo(item.cardNo)">{{item.displayContent}}</div>
                </div> -->
                <el-card class="box-card" v-if="telBox.length > 0" body-style="padding:0px">
                  <div class="text item" v-for="(item, index) in telBox" :key="index" @click="telTo(item.cardNo)">{{item.displayContent}}</div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="right-grid-bottom">
            <div class="title">绿色通道</div>
            <div class="right-grid-content">
              <el-row :gutter="60">
                <el-col :span="12" v-if="isDisabled('/venueOperation/order')">
                  <div class="right-one" @click="toNext('order')">
                    <img
                      style="width: 100px"
                      src="@/assets/images/venue.png"
                      srcset=""
                      alt=""
                    />
                    <div class="right-content-text">预约场馆</div>
                  </div>
                </el-col>
                <el-col :span="12" v-if="isDisabled('/venueOperation/member')">
                  <div class="right-one" @click="toNext('member')">
                    <img
                      style="width: 100px"
                      src="@/assets/images/member.png"
                      srcset=""
                      alt=""
                    />
                    <div class="right-content-text">新增会员</div>
                  </div>
                </el-col>
                <el-col :span="12" style="margin-top: 20px" v-if="isDisabled('/venueOperation/member')">
                  <div class="right-one" @click="toNext('member')">
                    <img
                      style="width: 100px"
                      src="@/assets/images/open-card.png"
                      srcset=""
                      alt=""
                    />
                    <div class="right-content-text">快速开卡</div>
                  </div>
                </el-col>
                <el-col :span="12" style="margin-top: 20px" v-if="isDisabled('/venueOperation/order')">
                  <div class="right-one" @click="toNext('order')">
                    <img
                      style="width: 100px"
                      src="@/assets/images/billing.png"
                      srcset=""
                      alt=""
                    />
                    <div class="right-content-text">开单</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <change-dialog
      ref="changeDialog"
      :Language="Language"
      :show="changeDialog.visible"
      @closeDialog="changeChangeDialog"
    ></change-dialog>
    <!-- 验证码弹窗 -->
    <status-dialog
      ref="statusDialog"
      :flag="flag"
      :id="id"
      :type="type"
      :errorResult="errorResult"
      :valiCode="valiCode"
      :valiTime="valiTime"
      :Language="Language"
      :show="statusDialog.visible"
      @closeDialog="changeStatusDialog"
    ></status-dialog>
    <!-- 修改密码 弹窗 -->
    <password-dialog
      ref="passwordDialog"
      :passwordData="passwordDialog.passwordData"
      :Language="Language"
      :show="passwordDialog.visible"
      :isEdit="passwordDialog.isEdit"
      @closeDialog="passwordConfirmDialog"
    ></password-dialog>
    <!-- 提现弹窗 -->
    <withdraw-dialog
      ref="withdrawDialog"
      :Language="Language"
      :show="withdrawDialog.visible"
      :isEdit="withdrawDialog.isEdit"
      @closeDialog="toWithdraw"
    ></withdraw-dialog>
    <!-- 查看详情弹窗 -->
    <detail-dialog
      ref="detailDialog"
      :Language="Language"
      :show="detailDialog.visible"
      :type="detailDialog.type"
      @sureDialog="sureDetailDialog"
      @closeDialog="changeDetailDialog"
    ></detail-dialog>
    <!-- 会员卡详情弹窗 -->
    <member-dialog
      ref="memberDialog"
      :Language="Language"
      :show="memberDialog.visible"
      @closeDialog="changeMemberDialog"
      @openError="openErrorDialog"
    ></member-dialog>
    <error-dialog
      ref="errorDialog"
      :Language="Language"
      :show="errorDialog.visible"
      :msg="errorDialog.message"
      @closeDialog="changeErrorDialog"
    ></error-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapMutations } from "vuex";
import changeDialog from "./dialog/changeDialog.vue";
import statusDialog from "./dialog/statusDialog.vue";
import Aside from "../components/aside.vue";
import Tab from "../components/tab.vue";
import apis from "../apis";
import passwordDialog from "./system/user/dialog/changePassword.vue";
import withdrawDialog from "./withdrawDialog/index.vue";
import mixin from "@/mixins/index";
import detailDialog from "./components/detailDialog.vue";
import memberDialog from "./dialog/memberDialog.vue";
import errorDialog from "./dialog/errorDialog.vue";

export default Vue.extend({
  name: "home",
  mixins: [mixin],
  components: {
    Aside,
    Tab,
    changeDialog,
    statusDialog,
    passwordDialog,
    withdrawDialog,
    detailDialog,
    memberDialog,
    errorDialog,
  },
  data() {
    return {
      dayTip: "",
      showLoginOut: false,
      venueIdCopy: "",
      // venueList: [],
      passwordDialog: {
        passwordData: {
          loginName: "",
          password: "",
        },
        visible: false,
        isEdit: false,
      },
      changeDialog: {
        visible: false,
      },
      statusDialog: {
        visible: false,
      },
      withdrawDialog: {
        visible: false,
      },
      detailDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      memberDialog: {
        visible: false,
      },
      errorDialog: {
        visible: false,
        message: '',
      },
      form: {
        qrCode: "",
        cardNo: '',
        telephone: '',
      },
      flag: true,
      valiCode: "",
      valiTime: "",
      errorResult: "",
      userName: "",
      tableData: [],
      tabForm: {
        venueOrderType: "-1",
      },
      timeArr: "",
      time: null,
      id: "",
      type: "",
      telBox: [],
    };
  },
  watch: {
    $route() {
      this.toStartTime();
    },
  },
  computed: {
    ...mapState([
      "authority",
      "Language",
      "buttonAuth",
      "USERNAME",
      "venueId",
      "venueList",
      "typeList",
      "merchantAccountDetailInfoDTO",
      "ROLES",
      "SystemName",
    ]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      console.log("route", route, meta, path);
      return path;
    },
    getSubject() {
      let arr = [];
      if (this.merchantAccountDetailInfoDTO.entityBaseInfoList) {
        if (this.merchantAccountDetailInfoDTO.entityBaseInfoList.length > 0) {
          arr = this.merchantAccountDetailInfoDTO.entityBaseInfoList.map(
            item => item.entityName,
          );
        }
      }
      return arr.join(",");
    },
    isShow() {
      if (this.ROLES.length > 0) {
         return this.ROLES[0].dataPermission === "4";
      }
      return false
    },
  },
  mounted() {
    this.$http
      .post(apis.venuevManagement_list, { id: "" })
      .then((res) => {
        if (res.data.code === 0) {
          this.setVenueList(res.data.rows || []);
          if (this.venueList.length > 0) {
            const venueListId = this.venueList.map((item) => {
              return item.id;
            });
            this.venueIdCopy = venueListId.includes(this.venueId)
              ? this.venueId
              : this.venueList[0].id;
            this.$nextTick(() => {
              this.setVenueId(this.venueIdCopy);
              this.getTabList();
              this.getCurrTime();
            });
          }
        }
      })
  },
  methods: {
    getMerchantInfo() {
      this.$http.get(apis.getMerchantInfo).then((res) => {
        if (res.data.code === 0) {
          this.setMerchantInfo(res.data);
        }
    });
    },
    handleOpenMerchantInfo() {
      this.changeDetailDialog(true, { id: this.merchantAccountDetailInfoDTO.merchantId });
    },
    toFundDetail(name, type) {
        this.$router.push({
          name,
          query: { id: this.merchantAccountDetailInfoDTO.merchantId, type },
        })
    },
    toWithdraw(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["withdrawDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.withdrawDialog.visible = dialogStatus;
      });
    },
    toStartTime() {
      if (this.$route.name === "home") {
        this.getTabList();
        this.getMerchantInfo();
        this.time = setInterval(() => {
          this.getCurrTime();
        }, 1000);
      } else {
        clearInterval(this.time);
        this.time = null;
      }
    },
    changePasswored() {
      this.passwordDialog.passwordData = {
        ...this.passwordDialog.passwordData,
      };
      this.$nextTick(() => {
        this.passwordDialog.visible = true;
        this.$refs.passwordDialog.getInfo();
      });
    },
    passwordConfirmDialog() {
      this.passwordDialog.visible = false;
    },
    openErrorDialog(status, data) {
      console.log(data);
      this.changeErrorDialog(status, data)
    },
    changeErrorDialog(status, data) {
      this.errorDialog.visible = status;
      this.errorDialog.message = data;
    },
    ...mapActions({
      setUserInfo: "setUserInfo",
      LoginOut: "LoginOut",
      setMerchantInfo: 'setMerchantInfo',
    }),
    ...mapMutations(["setVenueId", "setVenueList", "setServiceMemberInfo"]),
    reloadPage() {
      this.setVenueId(this.venueIdCopy);
      this.$nextTick(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      });
    },
    /**
     * @function 退出登录
     */
    loginOut() {
      this.showLoginOut = false;
      this.$http
        .post(apis.loginOut)
        .then((res) => {
          if (res.data.code === 0) {
            // 退出成功
            this.LoginOut();
            this.venueIdCopy = ''
            this.setServiceMemberInfo({})
            this.$refs.tab.clearTab();
            this.$router.push("/login");
          }
        })
        .catch(() => {
          this.LoginOut();
          this.setServiceMemberInfo({})
          this.$router.push("/login");
        });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeChangeDialog(dialogStatus) {
      this.changeDialog.visible = dialogStatus;
    },
    /**
     * @function 改变验票弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeStatusDialog(dialogStatus) {
      this.statusDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.form.qrCode = "";
        this.$nextTick(() => {
          this.$refs.customerInput.focus();
        });
      }
    },
    /**
     * @function 验票
     */
    checkTick() {
      if (this.form.qrCode !== "") {
        this.$http.post(apis.checkInByTickNo, this.form).then((res) => {
          if (res.data.code === 0) {
            if (res.data.rows.type === "DPH") {
              this.id = res.data.rows.orderDetailId;
            } else {
              this.id = res.data.rows.orderId;
            }
            this.type = res.data.rows.type;
            if (res.data.rows.tickType === "0") {
              this.flag = false;
              this.errorResult = res.data.rows.failedReason;
              this.valiCode = this.form.qrCode;
            } else {
              this.flag = true;
              this.valiCode = this.form.qrCode;
              this.valiTime = res.data.rows.validationTime;
            }
            this.changeStatusDialog(true);
            this.$refs.customerInput.blur();
            this.$refs.statusDialog.focus();
            // this.form.qrCode = ''
          } else {
            // this.$message.error(res.data.msg)
          }
        });
      } else {
        this.$message.error("请输入验票码");
      }
    },
    /**
     * @function 期限卡入场
     */
    checkCard() {
      if (this.form.cardNo !== "") {
        // let name = ''
        // this.venueList.forEach((item) => {
        //   if (item.id === this.venueId) {
        //     name = item.venueName
        //   }
        // }) // apis.timingCardCheckIn // post
        // this.$http.post(apis.timingCardCheckIn, {
        //   venueName: name,
        //   cardNo: this.form.cardNo,
        // }).then((res) => {
        //   if (res.data.code === 0) {
        //     // this.$message.success(res.data.msg)
        //   }
        // });
        this.changeMemberDialog(true, this.form.cardNo)
      } else {
        this.$message.error("请输入期限卡卡号");
      }
    },
    // 输入会员卡入场
    telTo(val) {
      console.log(val);
      this.changeMemberDialog(true, val)
    },
    changetTel(val) {
      if (val.length > 8) {
        this.$http.get(`${apis.getSimpleCardInfoByTelephone}?telephone=${this.form.telephone}&merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
          if (res.data.code === 0) {
            this.telBox = res.data.data
          }
        })
      } else {
        this.telBox = []
      }
    },
    getCurrTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
      const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
      const hh = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
      const mm = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
      const ss = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
      // const timeArr = [];
      const currDay = `${year}年${month}月${day}号`;
      const currTime = `${hh}时:${mm}分:${ss}秒`;
      const week = now.getDay();
      const weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.timeArr = `${currDay}  ${weekArr[week]}  ${currTime}`;
    },
    handleClick() {
      this.getTabList();
    },
    searchData() {},
    toNext(name) {
      this.$router.push({ name });
    },
    // 是否显示
    isDisabled(name) {
      let isHave = false
      this.authority.forEach((item) => {
        if (item.path === name) {
          isHave = true
        } else if (item.children && item.children.length > 0) {
          item.children.forEach((el) => {
            if (el.path === name) {
              isHave = true
            }
          })
        }
      });
      return isHave
    },
    toRefresh() {
      this.getTabList();
    },
    getTabList() {
      this.$http
        .post(
          `${apis.homeinfoList}?venueId=${this.venueId}&venueOrderType=${this.tabForm.venueOrderType}`,
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
          }
        });
    },
    // 查看详情-确认
    sureDetailDialog() {
      // console.log("++++++++查看详情");
    },
    /**
     * @function 改变详情弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} data 打开详情，传递数据
     */
    changeDetailDialog(dialogStatus, data = {}) {
      this.detailDialog.data = data;
      this.detailDialog.visible = dialogStatus;
      console.log(123);
      if (!dialogStatus) {
        this.detailDialog.data.ids = "";
        this.searchEvent();
      } else {
        console.log(456);
        this.$refs["detailDialog"].getDataQuery(data);
      }
    },
    changeMemberDialog(dialogStatus, data) {
      console.log(dialogStatus, 'dialogStatus');
      this.memberDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.searchEvent();
      } else {
        this.$refs["memberDialog"].getDataQuery(data);
      }
    },
  },
  activated() {
    // this.getTabList();
    this.$refs.customerInput.focus();
    this.toStartTime();
    this.$http
      .post(apis.venuevManagement_list, { id: "" })
      .then((res) => {
        if (res.data.code === 0) {
          this.setVenueList(res.data.rows || []);
          if (this.venueList.length > 0) {
            const venueListId = this.venueList.map((item) => {
              return item.id;
            });
            this.venueIdCopy = venueListId.includes(this.venueId)
              ? this.venueId
              : this.venueList[0].id;
            this.$nextTick(() => {
              this.setVenueId(this.venueIdCopy);
            });
          }
        }
      })
    this.$http.get(apis.info).then((res) => {
      this.setUserInfo(res.data);
      this.userName = res.data.userName;
      this.passwordDialog.passwordData = res.data;
    });
  },
});
</script>
<style lang="less">
@import "../assets/styles/mixin.less";
.detail-content-label .el-form-item__label {
  color: #929292 !important;
}
.footer-confirm {
  height: 32px !important;
  width: 160px !important;
  line-height: 8px !important;
  background: rgba(62, 103, 174, 1) !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  font-size: 14px !important;
  margin-right: 20px !important;
  cursor: pointer !important;
}
.footer-cancel {
  width: 160px !important;
  height: 32px !important;
  line-height: 8px !important;
  background: rgba(234, 236, 241, 1) !important;
  border-radius: 4px !important;
  color: rgba(62, 103, 174, 1) !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
</style>
<style lang="less" scoped>
@import "../assets/styles/mixin.scoped.less";
.home {
  // grid-template-columns: 220px auto;
  // display: grid;
  display: flex;
  flex-direction: row;
  background: #f8f9fa;
  .left_container {
    // width: 220px;
    height: 100vh;
    background: #ffffff;
  }
  .top_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .banner-rapper {
      background: url("../assets/images/menu_banner.png") left center no-repeat;
      height: 64px;
      background-size: cover;
      display: flex;
      justify-content: space-between;
      align-content: center;
      line-height: 64px;
      .left {
        color: rgba(45, 45, 45, 1);
        font-size: 20px;
        font-weight: 500;
        line-height: 64px;
        margin-left: 190px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      }
      .right {
        // margin-right: 116px;
        display: flex;
        flex-direction: row;
        .user-option-welcome {
          font-size: 14px;
          color: #606266;
        }
        .option-text {
          line-height: 30px;
          height: 30px;
          text-align: center;
          display: block;
          font-size: 14px;
          color: rgba(95, 95, 95, 1);
          cursor: pointer;
        }
        .venueId {
          margin: 0 36px;
          border-left: 1px solid #cbcfd3;
        }
      }
    }
  }
  .home-contaoner {
    margin: 10px 20px;
    height: calc(100vh - 85px);
    overflow: auto;
    display: grid;
    grid-template-columns: auto 330px;
    grid-column-gap: 20px;
    .left-grid-wrap {
      .banner {
        .banner-one,
        .banner-two,
        .banner-three {
          height: 160px;
          // background: url("../assets/images/bg-one.png") center no-repeat;
          background: #ffffff;
          border-radius: 5px;
          background-size: cover;
          display: flex;
          // align-items: center;
          flex-direction: column;
          .detail{
            display: flex;
            margin: 0;
            .detail-content {
              width: 250px;
              height: 40px;
              white-space: nowrap;
              overflow: hidden;
              /deep/.el-form-item__content{
                display: block;
              }
            .detail-content-text {
              color: #151515;
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .detail-content-text1 {
              color: #ff8417;
              font-weight: 400;
            }
          }
          }
          .detail-merchant{
            margin-bottom: 20px;
            .merchant-content{
              width: 380px;
            }
          }
          .detail-btn {
            height: 40px;
            line-height: 40px;
          }
          .banner-one-title {
            margin-top: 10px;
            height: 19px;
            border-left: 2px solid #40a6f9;
            line-height: 19px;
            text-indent: 13px;
            font-size: 14px;
            color: #40a6f9;
          }
          .img-content {
            margin-top: 10px;
            margin-left: 30px;
            .img-one {
              width: 72px;
              height: 70px;
            }
          }
          .text-content {
            margin-left: 20px;
            .text-content-one {
              font-size: 30px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #ffffff;
              line-height: 40px;
            }
            .text-content-two {
              font-size: 18px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
      .banner-img {
        height: 424px;
        width: 100%;
      }
      .default-button {
        margin-left: 30px;
        margin-top: 5px;
      }
      .welcome-text {
        height: 50px;
        font-size: 16px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: rgba(36, 45, 65, 1);
        font-weight: bold;
        line-height: 50px;
        font-size: 20px;
      }
      .support-text {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #e94040;
      }
      .green-passageway-wrap {
        margin-top: 10px;
        width: 100%;
        height: 78%;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-auto-rows: 40px auto;
        background: #fff;
        border-radius: 4px;
        border: 1px solid rgba(228, 233, 241, 1);
        .title-wrap {
          // display: flex;
          grid-column-start: 1;
          grid-column-end: 5;
          height: 40px;
          // background: #eef0f4;
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(46, 54, 73, 1);
          text-align: center;
          font-weight: 400;
          .title-wrap-left {
            width: 85%;
            float: left;
          }
          .title-wrap-right {
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
            margin-right: 20px;
            color: #bfbfbf;
            cursor: pointer;
          }
        }
        .icon-wrap {
          justify-self: center;
          // align-self: center;
          margin-top: 20px;
          width: 99%;
          img {
            width: 56px;
          }
          p {
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }
    .right-grid-wrap {
      // background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      .right-grid-top {
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 129px;
        background: rgba(255, 255, 255, 1);
        border-radius: 5px;
        background: url("../assets/images/bg-left.png") center no-repeat;
        background-size: cover;
        .today-time {
          text-align: center;
          line-height: 50px;
          height: 50px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #4f4f4f;
        }
        .today-weather {
          width: 350px;
          padding: 0 10px;
          margin-top: 30px;
          display: flex;
          .left-temperature {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 22px;
              height: 22px;
            }
            .left-temperature-text {
              font-size: 16px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #439bff;
            }
          }
          .wrap-right-header {
            // width: 100%;
            // padding: 0 10px;
            display: flex;
            // justify-content: space-between;
          }
          .right-content-list {
            display: flex;
            padding: 0 10px;
            justify-content: center;
            align-items: center;
            margin: auto;
            .right-content-one {
              position: relative;
              padding: 0 5px;
              width: 45px;
              .left {
                text-align: left;
                font-size: 10px;
                font-family: MicrosoftYaHei;
                color: #439bff;
              }
              .left-new {
                width: 45px;
                height: 16px;
              }
              .right {
                text-align: right;
                img {
                  width: 15px;
                }
              }
              .slash {
                top: 20px;
                right: 18px;
                position: absolute;
                height: 0px;
                border: 1px solid #909399;
                width: 20px;
                transform: rotate(135deg);
                -o-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
              }
            }
          }
        }
      }
      .right-grid-middle {
        margin-top: 20px;
        .telBox {
          background: #fff;
          padding: 20px 0;
          height: 90px;
          overflow: auto;
          div {
            padding-top: 5px;
            cursor: pointer;
          }
        }
        .text {
          font-size: 14px;
          cursor: pointer;
        }
        .item {
          padding: 15px 20px;
        }
        .item:hover{
          background: #F5F7FA;
        }
      }
      .right-grid-bottom {
        height: 73%;
        // background: red;
        overflow: hidden;
        background: #ffffff;
        margin-top: 10px;
        .title {
          height: 24px;
          border-left: 2px #439bff solid;
          margin-top: 20px;
          text-indent: 20px;
          color: #439bff;
        }
        .right-grid-content {
          height: 100px;
          // background: red;
          margin-top: 31px;
          padding: 0 25px;
          .right-one {
            cursor: pointer;
            .right-content-text {
              text-align: center;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #4f4f4f;
            }
          }
        }
      }
      .shop-title-text {
        font-size: 16px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: rgba(36, 45, 65, 1);
        text-align: center;
      }
      .ercode-img {
        width: 170px;
        height: 170px;
        margin: 11px auto;
        display: block;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        margin-top: 35px;
      }
      .tip-wrapper {
        font-size: 16px;
        color: rgba(60, 60, 60, 1);
        text-align: center;
        .tip-bottom {
          margin-top: 4px;
        }
      }
      .contact-wrap {
        // margin-top: calc(100vh - 450px);
        position: fixed;
        bottom: 10px;
        right: 65px;
        width: 220px;
        div {
          height: 20px;
          line-height: 20px;
          margin: 0 auto;
          display: block;
          margin-left: 57px;
          margin-bottom: 10px;
          img {
            height: 20px;
            float: left;
          }
          span {
            font-size: 14px;
            font-family: SourceHanSansSC-Normal, SourceHanSansSC;
            font-weight: 400;
            color: rgba(95, 95, 95, 1);
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
