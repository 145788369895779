<template>
  <div :class="[isCollapse? 'wrap-collapse wrap':'wrap']">
    <img src="@/assets/images/menu_logo.png" class="logo" alt="" width="124px" srcset="">
    <div class="menu_wrap">
      <el-menu
      ref="menu"
      background-color="#F5F6FA00"
      text-color="#72AEFC"
      active-text-color="#D7EAFF"
      router
      :default-active="defaultMenu"
      :collapse="isCollapse"
      unique-opened
      @select="onMenuSelect"
      :collapse-transition="true"
        class="el-menu-vertical-demo">
        <template v-for="(item, index) in authority">
          <el-submenu :index="item.path" :key="item.path+index" v-if="item.children && item.children.length > 0" v-show="!item.hidden">
            <template slot="title">
              <img class="menu-icon" :src="item.meta.icon" alt="" srcset="">
              <span class="main-menu">{{item.meta.title}}</span>
            </template>
            <template v-for="(children_item, i) in item.children">
              <el-menu-item class="sub-menu" v-show="!children_item.hidden && !children_item.children" :index="`${children_item.path}`" :key="children_item.path+i+'i'">{{children_item.meta.title}}</el-menu-item>
              <!-- 添加三级菜单-start -->
              <el-submenu v-show="!children_item.hidden && children_item.children && children_item.children.length > 0" :index="`${children_item.path}`" :key="children_item.path+i+'m'">
                <template slot="title">
                  <!-- <img class="menu-icon" :src="children_item.meta.icon" alt="" srcset=""> -->
                  <span class="main-menu">{{children_item.meta.title}}</span>
                </template>
                <!-- <el-menu-item class="sub-menu" v-show="!third_children_item.hidden" v-for="(third_children_item, i) in children_item.children" :index="`${third_children_item.path}`" :key="third_children_item.path+i">{{third_children_item.meta.title}}</el-menu-item> -->
                <!-- 添加四级菜单-start -->
                <template v-for="(third_children_item, i) in children_item.children">
                  <el-menu-item class="sub-menu" v-show="!third_children_item.hidden && !third_children_item.children" :index="`${third_children_item.path}`" :key="third_children_item.path+i+'i'">{{third_children_item.meta.title}}</el-menu-item>
                  <el-submenu v-show="!third_children_item.hidden && third_children_item.children && third_children_item.children.length > 0" :index="`${third_children_item.path}`" :key="third_children_item.path+i+'m'">
                    <template slot="title">
                      <!-- <img class="menu-icon" :src="third_children_item.meta.icon" alt="" srcset=""> -->
                      <span class="main-menu">{{third_children_item.meta.title}}</span>
                    </template>
                    <el-menu-item class="sub-menu" v-show="!fourth_children_item.hidden" v-for="(fourth_children_item, i) in third_children_item.children" :index="`${fourth_children_item.path}`" :key="fourth_children_item.path+i">{{fourth_children_item.meta.title}}</el-menu-item>
                  </el-submenu>
                </template>
                <!-- 添加四级菜单-end -->
              </el-submenu>
              <!-- 添加三级菜单-end -->
            </template>
            <!-- <el-menu-item class="sub-menu" v-show="!children_item.hidden && !children_item.children" v-for="(children_item, i) in item.children" :index="`${children_item.path}`" :key="children_item.path+i">{{children_item.meta.title}}</el-menu-item> -->
          </el-submenu>
          <!-- <el-menu-item :key="item.path+index+'q'" v-show="!item.hidden" v-else >
              <img class="menu-icon" :src="item.meta.icon" alt="" srcset="">
              <span class="main-menu" slot="title">{{item.meta.title}}</span>
          </el-menu-item> -->
          <el-menu-item :index="item.path" :key="item.path+index+'q'" v-else  v-show="!item.hidden">
              <img class="menu-icon" :src="item.meta.icon" alt="" srcset="">
            <span class="main-menu" slot="title">{{item.meta.title}}</span>
          </el-menu-item>
        </template>
      </el-menu>
      <img @click="onMenuCollapase" class="menu-collapse" src="../assets/images/menu_colla.png" alt="" srcset="">
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    authority: {
      required: true,
    },
    defaultMenu: {
      type: String,
      default: "/",
    },
  },
  mounted() {
  },
   data() {
      return {
        isCollapse: false,
      };
    },
  watch: {
    $route(to) {
      this.$refs.menu.activeIndex = to.fullPath
      console.log("to", to)
      console.log("authority", this.authority)
    },
  },
  methods: {
    onMenuSelect(value) {
      console.log("onMenuSelect -> value", value)
      // 如果是null 则是大屏
      if (value === null) {
        window.open("http://47.97.111.86:9000/");
      }
    },
    // menu点击切换更改
    onMenuCollapase() {
      this.isCollapse = !this.isCollapse;
    },
    /**
     * @function 页面跳转
     * @param {String} path 要跳转的页面
     */
    goUrl(path) {
      this.$router.push(path)
    },
  },
})
</script>
<style lang="less" scoped>
@import "../assets/styles/var.less";

.wrap{
  width: 220px;
  height: 100%;
  background: @aside-background;
  background-size: cover;
  position: relative;
  .menu-collapse {
    position: absolute;
    left: 208px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 42px;
    cursor: pointer;
  }
  .Logo{
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100px;
    height: 100px;
  }
  .menu_wrap {
    overflow-y: auto;
    height: calc(100% - 200px);
    /*修改滚动条样式*/
    &::-webkit-scrollbar{
      width:0;
      height:0;
      /**/
    }
    .main-menu {
      font-weight:500;
      // color:rgba(114,174,252,1);
      font-size:16px;
      .sub-menu {
        font-size:14px;
        font-weight:400;
      }
    }
  }
}
.wrap-collapse {
  width: 110px;
  .logo {
    width: 85px;
    padding-top: 58px;
  }
  .menu-collapse {
    left: 98px;
  }
  .el-menu {
    width: 100%;
    text-align: center;
  }
  .el-menu--popup-right-start {
    li {
      color: #fff;
    }
  }
}
</style>
<style lang="less" scoped>
.logo{
  margin: 0 auto;
  padding-top: 38px;
  padding-bottom: 38px;
  width: 105px;
  display: block;
}
</style>
<style lang="less">
@import url('../assets/styles/var.less');
.el-submenu__title {
  transition: none !important;
}
.el-menu {
  border-right: none !important;
}
.el-menu-item:hover{
  background: none !important;
}
.el-submenu__title{
  background: none !important;
}
.el-menu-item {
  // transition:border-color 0.3s, background-color 0.3s, color 0.3s;
  transition:none !important;
}
.el-menu-item.is-active{
  background:rgba(115,163,255,0.22) !important;
  border-left: 5px solid #73A3FF !important;
}
.menu-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 3px;
}
.menu-qrcode {
  .el-input__inner {
    border-radius:15px !important;
  }
}
.el-menu--vertical {
  .el-menu--popup-right-start {
    background: #fff !important;
  }
  .el-menu-item.is-active {
    border-left: 0 !important;
  }
  .el-menu-item:hover {
    background: rgba(115,163,255,0.22) !important;
  }
  .el-menu-item {
    color: #4F4F4F !important;
  }
}
.wrap-collapse {
  .el-menu-vertical-demo {
    .el-submenu.is-active{
      background:rgba(115,163,255,0.22) !important;
      border-left: 5px solid #73A3FF !important;
    }
  }
}
</style>
