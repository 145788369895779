<template>
  <div class="dialog-container">
    <el-dialog
      title="修改密码"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="500px">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名" prop="loginName">
              <el-input clearable :disabled="true" v-model="form.loginName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="密码" prop="password">
              <el-input clearable v-model="form.password" placeholder="新密码" maxlength="20" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="确认密码" prop="passwordAgain">
              <el-input clearable v-model="form.passwordAgain" placeholder="确认密码" maxlength="20" type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import ChangePasswordClass from './changePassword'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    passwordData: {
        type: Object,
        default: () => {},
    },
  },
  data() {
    return {
      form: new ChangePasswordClass('form'), // 实例化一个表单的变量
      rules: new ChangePasswordClass('rule', this.Language), // 实例化一个表单的规则
      depts: [], // 部门
      roleAll: [], // 角色树状的值
    }
  },
//   watch: {
//     // eslint-disable-next-line
//     'form.deptId'() {
//       if (this.$refs.cascader) {
//         this.$refs.cascader.dropDownVisible = false
//       }
//     },
//   },
  computed: {
    // deptsFormat() {
    //   console.log(this.depts)
    //   const result = []
    //   const format = (arr) => {
    //     arr.forEach((item) => {
    //       if (!item.children) {
    //         result.push(item)
    //       } else {
    //         format(item.children)
    //       }
    //     })
    //   }
    //   if (this.depts.length > 0) {
    //     format(this.depts)
    //   }
    //   return result
    // },
  },
  created() {
    //   this.form.password = this.passwordData.password;
    // this.getPermissions()
    // this.getRoleData()
  },
  methods: {
    getInfo() {
        this.form.loginName = this.passwordData.loginName;
        console.log("created", this.passwordData)
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new ChangePasswordClass('form')
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.passwordAgain !== this.form.password) {
            this.$message.error('确认密码不一致，请重新确认输入!')
            return;
          }
          this.$http.post(apis.resetPassword, {
            ...this.passwordData,
            password: this.form.password,
          }).then((res) => {
            if (res.data.code === 0) {
                this.$message.success('修改成功')
              // 调用关闭方法，不要再写一次
              this.closeDialog()
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.form.userId = data.userId
      this.form.password = data.password
      this.form.loginName = data.loginName
      this.form.status = data.status
      this.form.remark = data.remark
      this.form.deptId = data.deptId
      this.form.roleIds = data.roleIds
    },
    /**
     * @function 获取全部的角色
     */
    getRoleData() {
      this.$http.get(apis.role_all).then((res) => {
        if (res.data.code === 0) {
          this.roleAll = res.data.rows
        }
      })
    },
    // 获取部门
    getPermissions() {
      this.$http.get(apis.dept_list_enable).then((res) => {
        this.buildtree(res.data.rows, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId,
            value: item.deptId,
            label: item.deptName,
            children: [],
          }
          this.buildtree(list, child.children, item.deptId)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
