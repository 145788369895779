/*
用于判断是否拥有指权限，
用法：v-auth="'delete'" 那么当没有拥有delete权限的时候，该元素不显示
*/

// import Vue from 'vue'
import store from '@/store'
import { includes } from 'lodash'

/**
 * 返回是否拥有某项权限
 * @param {string} authorization 权限的名称
 * @returns {boolean}
 */
function hasAuthorization(authorization) {
    return includes(store.state.buttonAuth, authorization)
}

export default {
    componentUpdated(el, binding) {
        const value = binding.value
        // console.log(el)
        // console.log(binding)
        // console.log(vnode)
        // console.log(value)

        // console.log()
        if (hasAuthorization(value)) {
            return
        }

        el.parentElement.removeChild(el);
    },
}
